<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="welcomeTipsOffcanvas" data-bs-backdrop="static" aria-labelledby="welcomeTipsOffcanvasOffcanvasLabel">
    <div class="offcanvas-header">
      <!-- <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button> -->
      <h5 class="offcanvas-title d-block" id="welcomeTipsOffcanvasOffcanvasLabel">
        {{ $t('welcomeTips.enjoy') }}
      </h5>
    </div>
    <div class="offcanvas-body">
      <h4>{{ $t('welcomeTips.newExperience') }}</h4>
      <img class="logo dark-logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">
      <img class="logo light-logo" src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo white">
      <!-- <img class="logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo white"> -->
      <h5>{{ $t('welcomeTips.onTheAir') }}</h5>
    </div>

    <div class="offcanvas-footer">
      <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="offcanvas" data-test="btnClose" @click="doAccept">{{ $t('close') }}</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  .offcanvas-body {
    display:                            flex;
    flex-direction:                     column;
    justify-content:                    flex-start !important;
    gap:                                26px;
    padding-top:                        3rem;

    h4,
    h5 {
      color:                            var(--Brand-Primary);
      text-align:                       center;
    }

    h4 {
      font-weight:                      700;
      letter-spacing:                   7.4px;
      text-transform:                   uppercase;
    }

    h5 {
      color:                            var(--Brand-Primary, #1B294A);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      letter-spacing:                   2.4px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn {
      font-size:                        var(--font-size-14);
      font-weight:                      700;
      line-height:                      normal;
      letter-spacing:                   4.2px;
      text-align:                       center;
      text-transform:                   uppercase;
      // gap:                              10px;
      // flex:                             1 0 0;
      width:                            100%;
      padding:                          20px;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'AcceptanceWelcomeTipsOffcanvas',
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    offcanvas_welcome: null,
  }),
  mounted() {
  },
  methods: {
    show(options = {}) {
      this.offcanvas_welcome = new bootstrap.Offcanvas(document.getElementById('welcomeTipsOffcanvas')).show();

      const userStore = useUserStore()
      this.session = userStore.sessionInfo

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      this.offcanvas_welcome = null;
      this.resolvePromise(true)
    },

    doCancel() {
      this.offcanvas_welcome = null;
      this.resolvePromise(false)
    },

    async doAccept() {
      NProgress.start()
      try {
        let response = await this.$userService.acceptWelcomeTips()
        const userStore = useUserStore()
        userStore.welcomeAccepted(response)
        console.log('save', userStore);

        this.$nextTick(() => {
          this.doConfirm()
        });
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
    }
  }
}
</script>
