<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="clinic-template-list" id="template-list" v-if="!isEditTemplate">
      <BaseInfiniteTable class="no-border" v-if="tableTemplates" :tabledata="tableTemplates" @item-opened="toDetails"/>

      <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableTemplates.items.length > 20" @click="goTop">
        <IconArrowUp/>
      </button>
    </div>

    <div class="clinic-edit-box" v-else>
      <ClinicTemplateEdit :template_id="template ? template.id : null" @cancel-edit="cancelEditTemplate"></ClinicTemplateEdit>
    </div>

    <div class="action-bar" v-if="!isEditTemplate">
      <button class="btn btn-success" type="button" name="button" data-test="btnBack" v-if="!isEditTemplate" @click="isEditTemplate = !isEditTemplate">
        <span>{{ $t('clinic.newTemplate') }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.clinic-template-list {
  padding:                         0 10px 10px 10px;
}

.action-bar {
  height:                          64px;
  padding:                         10px 20px;
  border-top:                      1px solid var(--Shades-30);
  display:                         flex;
  justify-content:                 flex-start;
  align-items:                     center;
  gap:                             10px;
}

.btn {
  @include                         border_radius(99px);
  font-size:                       var(--font-size-12);
  font-weight:                     700;
  letter-spacing:                  3.64px;
  text-transform:                  uppercase;
  height:                          44px;
  padding:                         15px 20px;
}

.btn-success {
  color:                           var(--Shades-W);
  background:                      var(--Brand-Secondary);
  border:                          1px solid var(--Brand-Secondary);
}

@media (max-width: 991px) {
  .clinic-template-list {
    height:                        calc(100dvh - 258px);
    max-height:                    calc(100dvh - 258px);
    padding:                       0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .clinic-template-list {
    height:                        calc(100dvh - 303px);
    // max-height:                    calc(100dvh - 258px);
  }
}
</style>

<script>
import ClinicTemplateEdit from './ClinicTemplateEdit.vue'

export default {
  name: 'ClinicTemplateTab',
  components: {
    ClinicTemplateEdit,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableTemplates: null,
    isEditTemplate: false,
    template: null,
  }),
  mounted: async function() {
    this.template = null
    this.fetchData();
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.currentPage = 1

        this.fetchData()
      })
    },
    async fetchData() {
      this.alert.message = undefined;

      this.loading = true
      NProgress.start()
      try {
        let params = {
          // page: this.currentPage,
        }

        let response = await this.$clinicService.listTemplates(params);
        console.log(">>>>>> fetch Templates >> ", response)

        this.tableTemplates = {
          'items': response.result,
          'columns': [
            {name: this.$i18n.t('name'), type: 'string', value: 'name', width: '35%'},
            {name: this.$i18n.t('description'), type: 'string', value: 'description', width: '65%'},
          ]
        }
      } catch (err) {
        this.alert = showAlert('request_error', 'alert-danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toDetails(values) {
      if (!values) {
        return
      }
      let item = values.item
      if (!item) {
        return
      }
      this.template = item;
      this.isEditTemplate = true;
    },
    cancelEditTemplate() {
      this.template = null
      this.isEditTemplate = false;
    }
  }
}
</script>
