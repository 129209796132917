<template>
  <div class="offcanvas-body scrollbar">
    <p>{{ $t('exam.fillForm') }}</p>

    <vee-form v-slot="{ handleSubmit }" as="div">
      <form @submit.prevent.stop="handleSubmit(doSave)" @keydown.enter="$event.preventDefault()">
        <div class="form-floating">
          <vee-field name="firstName" v-model="patient.firstName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="firstName" :placeholder="$t('patient.firstName')" data-test="inputPatientFirstName">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="firstName">{{ $t('patient.firstName') }}*</label>
        </div>
        <div class="form-floating">
          <vee-field name="lastName" v-model="patient.lastName" rules="required" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="lastName" :placeholder="$t('patient.lastName')" data-test="inputPatientLastName">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="lastName">{{ $t('patient.lastName') }}*</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="telephone1" v-model="patient.telephone2" :placeholder="$t('patient.telephone1')" data-test="inputPatientPhone1">
          <label for="telephone1">{{ $t('patient.telephone1') }}</label>
        </div>
        <div class="form-floating">
          <vee-field name="email" v-model="patient.email" rules="email" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createClinic.email')" data-test="inputPatientEmail">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="email">{{ $t('createClinic.email') }}</label>
        </div>
        <div class="form-floating">
            <vee-field name="birthday" v-model="patient.birthday" :rules="`date:${$t('dateFormat')}`" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control"  v-maska :data-maska="patient.birthday ? $t('inputMask.date') : ''" :placeholder="$t('patient.birthday')" data-test="inputPatientBirthday">
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="birthday">{{ $t('patient.birthday') }}</label>
          </div>
        <div class="form-floating" v-if="mandatoryDocument === 'document2' || mandatoryDocument === 'none'">
          <vee-field name="document2" v-model="patient.document2" :rules="`${mandatoryDocument === 'document2' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document2" v-maska :data-maska="$t('inputMask.document2')" :placeholder="$t('document2')" data-test="inputPatientDocument2">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="document2">
            {{ $t('document2') }}
            <span v-if="mandatoryDocument === 'document2'">*</span>
          </label>
        </div>
        <div class="form-floating" v-if="mandatoryDocument === 'document0'">
          <vee-field name="document0" v-model="patient.document0" :rules="`${mandatoryDocument === 'document0' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document0" v-maska :data-maska="$t('inputMask.document0')" :placeholder="$t('document0')" data-test="inputPatientDocument0">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="document0">
            {{ $t('document0') }}
            <span v-if="mandatoryDocument === 'document0'">*</span>
          </label>
        </div>
        <div class="form-floating" v-if="mandatoryDocument === 'document1'">
          <vee-field name="document1" v-model="patient.document1" :rules="`${mandatoryDocument === 'document1' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document1" :placeholder="$t('document1')" data-test="inputPatientDocument1">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="document1">
            {{ $t('document1') }}
            <span v-if="mandatoryDocument === 'document1'">*</span>
          </label>
        </div>
        <div class="form-floating" v-if="mandatoryDocument === 'document3'">
          <vee-field name="document3" v-model="patient.document3" :rules="`${mandatoryDocument === 'document3' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document3" v-maska :data-maska="$t('inputMask.document3')" :placeholder="$t('document3')" data-test="inputPatientDocument3">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="document3">
            {{ $t('document3') }}
            <span v-if="mandatoryDocument === 'document3'">*</span>
          </label>
        </div>
        <div class="form-floating" v-if="mandatoryDocument === 'document4'">
          <vee-field name="document4" v-model="patient.document4" :rules="`${mandatoryDocument === 'document4' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="document4" v-maska :data-maska="$t('inputMask.document4')" :placeholder="$t('document4')" data-test="inputPatientDocument4">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="document4">
            {{ $t('document4') }}
            <span v-if="mandatoryDocument === 'document4'">*</span>
          </label>
        </div>
        <div class="form-floating">
          <vee-field name="mrn" v-model="patient.mrn" :rules="`${mandatoryDocument === 'mrn' ? 'required|min:1' : ''}`" v-slot="{ field, errorMessage }">
            <input v-bind="field" type="text" class="form-control" id="mrn" :placeholder="$t('mrn')" data-test="inputPatientMrn">
            <span class="vee-error">{{ errorMessage }}</span>
          </vee-field>
          <label for="mrn">
            {{ $t('mrn') }}
            <span v-if="mandatoryDocument === 'mrn'">*</span>
          </label>
        </div>
        <div class="form-floating">
          <!-- <label for="gender">{{ $t('patient.gender') }}</label> -->
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="patientGender" id="genderM" value="M" v-model="patient.gender">
            <label class="form-check-label" for="genderM">{{ $t('patient.male') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="patientGender" id="genderF" value="F" v-model="patient.gender">
            <label class="form-check-label" for="genderF">{{ $t('patient.female') }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="patientGender" id="genderNA" value="NA" v-model="patient.gender">
            <label class="form-check-label" for="genderNA">{{ $t('patient.notInform') }}</label>
          </div>
        </div>

        <div class="offcanvas-footer">
          <button type="submit" name="button" class="btn btn-success" data-test="btnAdd">{{ $t('assignPatient.addPatient') }}</button>
        </div>
      </form>
    </vee-form>

  </div>
</template>

<style lang="scss" scoped>

.offcanvas-body {
  padding:                            0;

  &.scrollbar {
    padding-right:                    1rem;
  }

  &,
  form {
    display:                          flex;
    flex-direction:                   column;
    gap:                              26px;

  }

  p:not(.patient-selected) {
    color:                            var(--Brand-Primary, #1B294A);
    font-size:                        var(--font-size-14);
    font-weight:                      300;
    line-height:                      140%; /* 19.6px */
    margin-bottom:                    0;
  }

  .form-check {
    margin:                           0;

    &:not(:last-child) {
      margin-bottom:                  26px;
    }

    input {
      width:                          24px;
      margin:                         0;
    }
  }
}

.btn {
  font-size:                          var(--font-size-14);
  font-weight:                        700;
  line-height:                        normal;
  letter-spacing:                     4.2px;
  text-align:                         center;
  text-transform:                     uppercase;
  width:                              100%;
  margin:                             0;
  padding:                            20px;
}

.sidenav-offcanvas-footer {
  display:                            flex;
  flex-direction:                     column;
  gap:                                26px;

  .btn-success {
    color:                            var(--Shades-W, #FFF);
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'ExamPatientAddFormData',
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      mrn: '',
      birthday: '',
      birthdayAux: '',
      document0: '',
      document1: '',
      document2: '',
      document3: '',
      mothersName: '',
      gender: 'M',
      weight: '',
      height: '',
      telephone1: '',
      telephone2: '',
      email: '',
      address: '',
      addressNumber: '',
      district: '',
      postalCode: '',
      city: '',
      state: '',
      country: '',
      other: '',
    },
    mandatoryDocument: null,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.mandatoryDocument = this.session.currentClinic.mandatoryDocument
  },
  methods: {
    async doSave() {
      try {
        this.alert.message = null;
        console.log('entrou tryyy');
        // TODO FIX check empty mandatory document on clinic configuration (match Eyer attribute)
        const mandatoryDocumentWasSet = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        if (mandatoryDocumentWasSet) {
          if (_.isEmpty(this.patient[this.mandatoryDocument]))
            return
        }

        let params = {
          patient: this.patient,
        }

        console.log('patient', this.patient);

        this.loading = true
        NProgress.start()
        let response = await this.$patientService.save(params)

        this.$message.popup(this, 'general.createdSuccess', 'operationSuccess', 'success')

        this.$emit('patient-created', response.result)
      } catch(err) {
        this.$message.popup(this, 'general.createdError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.createdError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    getMandatoryPatient(){
        let mandatoryIsRequired = !_.isEmpty(this.mandatoryDocument) && this.mandatoryDocument != 'none'
        console.log('mandatory is required: ', mandatoryIsRequired)
        if (!mandatoryIsRequired){
            return this.patient.document2
        }

        return ""
    },
    getDataMask() {
      return
    }

  }
}
</script>
