<template>
  <div class="sidebar ease-all" id="sidebarMenu">
    <div class="menu ease-width desk">
      <div class="header-logo flex-between">
        <img class="dark-logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo">
        <img class="light-logo" src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo">

        <button id="btnCollapseMenu" ref="btnCollapseMenu" class="btn-icon flex-center" type="button" name="button" data-test="btnCollapse" @click="$emit('docollapse')">
          <IconHamburger/>
        </button>

        <teleport to="body" v-if="showTips">
          <div class="tips" id="tips">
            <button class="no-btn" type="button" name="button" @click="closeAndIncrementTipsCount">
              <IconClose/>
            </button>
            <p>
                {{ $t('welcomeTips.menu' )}}
            </p>
          </div>
        </teleport>
      </div>

      <div class="menu-content flex-between">
        <MenuDesktopLinks/>

        <div class="">
          <!-- <div class="storage-box flex-center">
            <p>plano gratuito</p>

            <p>armazenamento</p>

            <MenuStorageBar/>

            <button class="btn btn-outline-secondary" type="button" name="button">
              melhorar plano
              <IconArrowUp/>
            </button>
          </div> -->

          <div class="powered-box">
            <p>POWERED BY</p>
            <a href="https://phelcom.com/" class="logo" target="_blank" data-test="anchorPhelcomLink">
              <img class="dark-logo" src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
              <img class="light-logo" src="@/assets/images/phelcom/phelcom_logo_horizontal_white.svg" alt="">
              <IconPhelcomEye/>
            </a>
            <p>v {{ appVersion }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="menu ease-width mob">
      <MenuMobileLinks/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tips {
  top:                            4.5rem;
  left:                           3.5rem;
}

.menu-content {
  flex-direction:                 column;
  align-items:                    stretch;
  height:                         calc(100% - 30px);
}

.storage-box {
  flex-direction:                 column;
  gap:                            20px;
  padding-bottom:                 30px;

  .progress-box {
    width:                        100%;
  }

  .btn-outline-secondary {
    gap:                          10px;
  }

  p {
    color:                        var(--Brand-Primary);
    font-size:                    var(--font-size-10);
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               2.15px;
    text-transform:               uppercase;
    margin-bottom:                0;
    &:first-child {
      @include                    border_radius(99px);
      color:                      #C1FFEA;
      padding:                    5px 20px;
      background:                 var(--Brand-Secondary);
    }
  }
}

.powered-box {
  display:                        flex;
  flex-direction:                 row;
  justify-content:                center;
  align-items:                    center;
  gap:                            10px;
  p {
    color:                        var(--Brand-Primary);
    font-size:                    var(--font-size-8);
    font-weight:                  400;
    line-height:                  normal;
    letter-spacing:               1.72px;
    text-transform:               uppercase;
    margin:                       0;
  }
  .logo {
    color:                        #1B294A;
    text-decoration:              none;
    width:                        86px;
    img {
      width:                      100%;
    }
    svg {
      display:                    none;
    }
  }
}

  .sidebar {
    position:                     relative;
    display:                      inline-flex;
    align-items:                  flex-start;
    flex-direction:               column;
    width:                        230px;
    height:                       100vh;
    padding:                      15px 10px;
    background:                   var(--Shades-20);
    z-index:                      4;
    .menu {
      min-height:                 100%;
      width:                      100%;
      &.mob {
        display:                  none;
      }
    }
    .header-logo {
      img {
        width:                    133px;
      }
      .btn-icon {
        width:                    30px;
        height:                   30px;
        padding:                  unset;
        border:                   1px solid var(--Shades-30-Border);
        background:               var(--Shades-20);

        svg {
          width:                  20px;
          height:                 20px;
        }
      }
    }
  }

  .retract {
    .sidebar {
      width:                      55px;
      height:                     100vh;
      padding:                    15px 10px;
      .menu {
        .header-logo {
          img {
            display:              none;
          }
          .btn-icon {
            margin:               0 auto;
          }
        }
        .menu-content {
          align-items:            center;
        }
      }
    }

    .powered-box {
      flex-direction:             column-reverse;
      p {
        font-size:                7px;
      }
      p:first-child,
      img {
        display:                  none;
      }
      svg {
        display:                  block;
      }
      .logo {
        width:                    auto;
      }
    }
  }

  @media (max-width: 991px) {
    .main.retract .sidebar,
    .main.retract .menu,
    .sidebar {
      height:                     55px !important;
      padding:                    0 !important;
      width:                      100% !important;
      background:                 unset;
      z-index:                    100;
    }
    .menu {
      &.desk {
        display:                  none;
      }
      &.mob {
        display:                  block !important;
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'
import MenuDesktopLinks from './MenuDesktopLinks.vue'
import MenuMobileLinks from './MenuMobileLinks.vue'
import MenuProfile from './MenuProfile.vue'
import MenuStorageBar from './MenuStorageBar.vue'
import WizardLevel from '@/components/WizardLevel.vue'

export default {
  name: 'menu',
  components: {
    MenuDesktopLinks,
    MenuMobileLinks,
    MenuProfile,
    MenuStorageBar,
    WizardLevel,
  },
  data: () => ({
    collapse: false,
    session: null,
    appVersion: process.env.VUE_APP_VERSION,
    showTips: true,
  }),
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    if (this.session.collaborator.welcomeTips)
      this.showTips = false
  },
  methods: {
    checkMobile() {
    	this.isMobile = false;
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;
    },
    doCollapse(value) {
      this.collapse = !this.collapse;

      if (this.collapse) {
        $('body').addClass('collap')
      } else {
        $('body').removeClass('collap')
      }

      // if ($('body.collap').length > 0) {
      //   $('body').removeClass('collap')
      //   console.log($('body'));
      // }
    },
    closeAndIncrementTipsCount() {
      this.showTips = false
      const userStore = useUserStore()
      userStore.incrementTipsCount(1)
    },
    settingsMenu: async function() {
      self.$router.push({name: 'proxy-router', params: {
        target: 'settings',
        data: JSON.stringify({})
      }})
    },
  }
}

</script>
