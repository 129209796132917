<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path d="M8.57422 0.243003C8.50278 0.150602 8.4069 0.0800385 8.29744 0.0392989C8.18797 -0.00144073 8.06928 -0.0107304 7.95481 0.012481C5.7453 0.414912 3.74412 1.57208 2.29322 3.28625C0.842312 5.00042 0.0317376 7.16521 0 9.4107C0.0459858 11.9948 1.11479 14.4552 2.9721 16.2526C4.82941 18.0499 7.32367 19.0375 9.90805 18.9989C11.7957 19.0097 13.6474 18.4832 15.2469 17.4809C16.8463 16.4786 18.1276 15.0418 18.9408 13.3384C18.9901 13.2327 19.0091 13.1153 18.9959 12.9994C18.9827 12.8834 18.9377 12.7734 18.8659 12.6814C18.7941 12.5894 18.6983 12.5191 18.589 12.4781C18.4797 12.4372 18.3612 12.4272 18.2467 12.4493C17.6824 12.5593 17.1089 12.6148 16.5341 12.6152C14.2853 12.6546 12.1127 11.8007 10.4926 10.2407C8.87247 8.68079 7.93707 6.54211 7.89148 4.29361C7.89171 3.12023 8.15033 1.96129 8.64896 0.899105C8.69834 0.793518 8.7175 0.676311 8.70431 0.560494C8.69112 0.444677 8.64609 0.334781 8.57422 0.243003ZM17.2042 13.859C16.4019 15.0564 15.3159 16.0366 14.0429 16.7124C12.7698 17.3882 11.3493 17.7386 9.90805 17.7323C7.65949 17.7713 5.4872 16.9173 3.86736 15.3574C2.24751 13.7975 1.31229 11.659 1.26669 9.4107C1.28968 7.66375 1.85857 5.96774 2.89366 4.56023C3.92875 3.15272 5.3782 2.1042 7.03899 1.56154C6.76413 2.44618 6.62448 3.36726 6.62479 4.29361C6.65555 5.62793 6.958 6.94198 7.51368 8.15549C8.06936 9.36901 8.86659 10.4565 9.85669 11.3516C10.8468 12.2467 12.009 12.9306 13.2723 13.3615C14.5356 13.7924 15.8734 13.9612 17.2042 13.8578V13.859Z" fill="#3CB391"/>
  </svg>
</template>

<style lang="css" scoped>
</style>

<script>
export default {
  name: 'IconMoon'
}
</script>
