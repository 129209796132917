<template>
  <svg class="icon-phelcom-eye" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M13.8868 1.66988L13.861 1.65009C12.4996 0.615397 10.7964 0 8.94776 0C4.49783 0 0.888764 3.56418 0.870932 7.96727L0.873699 7.96605C0.870624 7.97762 0.868164 7.98919 0.868164 7.99985C0.868164 8.01111 0.870624 8.0236 0.87462 8.03608L0.870932 8.03456C0.889993 12.4367 4.49845 16 8.94776 16C10.7955 16 12.4984 15.3849 13.8592 14.3508L13.8868 14.3298C2.24274 7.99985 2.24274 7.99985 13.8868 1.66988Z" fill="#1B294A"/>
    <path d="M14.1549 3.25294C14.1549 3.25294 13.9753 3.0943 13.9747 3.09369C7.80097 7.96022 7.90335 7.96875 14.043 12.905C14.0497 12.8992 14.2794 12.6882 14.2791 12.6879C13.7322 12.0679 11.0866 9.58565 11.0866 8.00255C11.0866 6.26232 13.9661 3.45757 14.1549 3.25294Z" fill="#1B294A"/>
    <path d="M16.1323 9.367L14.0976 8.00132L16.1215 6.64263C15.8298 5.31531 15.1282 4.14025 14.1545 3.25293C13.9658 3.45786 11.0859 6.26262 11.0859 8.00253C11.0859 9.58594 13.7318 12.0685 14.2787 12.6882C15.1992 11.8045 15.8596 10.6566 16.1323 9.367Z" fill="#3CB391"/>
  </svg>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'IconPhelcomEye'
}
</script>
