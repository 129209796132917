<template>
  <div class="main-box exam-list-box" v-loader.opacity="loading">
    <div class="exam-list-header">
      <div class="">
        <div class="title-box clear">
          <h2 class="title">{{ $t("exam.examsList") }}</h2>

          <button class="btn btn-outline-secondary" type="button" name="button" data-test="btnAddNewExam"
            @click="openOffCanvasNewExam">
            <IconPlus/>
            <span>{{ $t('exam.createExam') }}</span>
          </button>


          <div class="dropdown">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <IconDot class="icon-dot"/>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" @click="openOffcanvasExamDownload">{{ $t('exam.exportExam') }}</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="">
        <div class="btn-group" role="group" aria-label="Action button group">
          <input type="radio" class="btn-check" name="btn-radio" id="btnGrid" autocomplete="off"
            :checked="tableType == 'grid'" @click="setTableLocalStorage('grid')">
          <label class="btn btn-outline-secondary" for="btnGrid">
            <IconGrid />
            <span>{{ $t('exam.grid') }}</span>
          </label>

          <input type="radio" class="btn-check" name="btn-radio" id="btnList" autocomplete="off"
            :checked="tableType == 'list'" @click="setTableLocalStorage('list')">
          <label class="btn btn-outline-secondary" for="btnList">
            <IconList />
            <span>{{ $t('exam.list') }}</span>
          </label>
        </div>

        <teleport to="body" v-if="showTips">
          <div class="tips" id="tips">
            <button class="no-btn" type="button" name="button" @click="closeAndIncrementTipsCount">
              <IconClose />
            </button>
            <p>
                {{$t('welcomeTips.examModes')}}
            </p>
          </div>
        </teleport>
      </div>
    </div>

    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div ref="tableBox" id="tableBox">
      <ExamTableList :table_exam="tableExam" :thumb_path="thumbDataPath" v-if="tableExam && tableType == 'list'"
        @do-assign="openAssignOffcanvas" @item-opened="examSelected" @loadmore="fetchMore('scroll')" @loadmoretop="fetchMore('top')"
        @load-more-months="loadMoreMonths" />

      <ExamGridList :table_exam="tableExam" :thumb_path="thumbDataPath" :data_path="dataPath"
        v-if="tableExam && tableType == 'grid'" @do-assign="openAssignOffcanvas" @item-opened="examSelected" @loadmoretop="fetchMore('top')"
        @loadmore="fetchMore('scroll')" @load-more-months="loadMoreMonths" />
    </div>

    <!-- <button class="btn btn-outline-secondary btn-load" type="button" name="button"
            v-if="this.tableExam.items && (this.tableExam.items.length == this.tableExam.total)" @click="loadMoreMonths">
      {{ $t('exam.loadMore') }}
    </button> -->

    <button class="btn-icon btn-top" type="button" name="button" v-if="currentPage > 1 && tableExam.items.length > 20"
      @click="goTop">
      <IconArrowUp />
    </button>

    <teleport to="body">
      <!-- <ExamDownloadModal v-if="showModalDownload" @close="showModalDownload = false"></ExamDownloadModal> -->
      <ExamDownloadOffcanvas :offcanvas_id="'ExamDownloadOffcanvas'" ref="examDownloadOffcanvas"/>
      <ExamNewOffcanvas ref="examNewOffcanvas" @close="closeOffCanvasDoFilter()"/>
      <ExamAssignPatientOffcanvas :offcanvas_id="'ExamAssignPatientOffcanvas'" ref="examAssignPatientOffcanvas" @patient-assigned="closeAssignOffCanvas"/>
    </teleport>
  </div>
</template>

<style lang="scss" scoped>
.tips {
  top: 7.5rem;
  right: 7rem;
}

.dropdown-toggle {
  &.show {
    z-index: 3 !important;
  }

  &:after {
    display: none;
  }
}

.dropdown-menu {
  @include box_shadow(0px 1px 6px 0px rgba(0, 0, 0, 0.40));
  // display: inline-flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--Shades-30, #D8D8D8);
  background: var(--Shades-W, #FFF);
  z-index: 999;
  // top:                           -50px !important;
  // left:                          -10px !important;

  &,
  .dropdown-item {
    @include border_radius(10px);
  }

  .dropdown-item {
    color: var(--Brand-Primary, #1B294A);
    font-size: var(--font-size-12);
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.12px;
    text-transform: uppercase;
    padding: 15px;
  }
}

.exam-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding-bottom:                1.5rem;
  padding: 15px 20px;
  border-bottom: 1px solid var(--Shades-20);

  >div {
    &:first-child {
      display: flex;
      align-items: center;
    }
  }

  .btn-group {
    .btn-outline-secondary:not(.dropdown-toggle) {
      @include border_radius(99px 0 0 99px);
      color: var(--Shades-70);
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      margin: 0;
      padding: 10px 15px;
      background: var(--Shades-W);
      border: 1px solid var(--Shades-30-Border);

      &:focus,
      &:hover,
      &:active,
      &.active {
        color: var(--Complementary-Success);
        background: var(--Complementary-Success-BG);
        border: 1px solid var(--Complementary-Success);
      }

      &:last-child {
        @include border_radius(0 99px 99px 0);
        border-left: 0;
      }
    }

    .btn-check:checked+.btn-outline-secondary {
      color: var(--Complementary-Success);
      background: var(--Complementary-Success-BG);
      border: 1px solid var(--Complementary-Success);
    }
  }
}

.title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: auto;
  margin: 0;
  padding: 0;

  .btn-outline-secondary {
    font-weight: 400;
    letter-spacing: 3.12px;
    gap: 10px;
    padding: 10px 15px 10px 10px;
    margin: 0;

    &.dropdown-toggle {
      width: 46px;
      height: 40px;
      padding: 13px;
      background: var(--Shades-10);
    }
  }
}

.filter-box {
  gap: 10px;

  >label,
  .btn-outline-secondary {
    color: var(--Shades-70, #404040);
  }

  >label {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }

  .btn-outline-secondary {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    padding: 10px 15px;
    border: 1px solid var(--Shades-30);
    background: var(--Shades-W);

    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--Shades-30);
      background: var(--Shades-W);
    }
  }
}

.title {
  color: var(--Shades-90);
  text-overflow: ellipsis;
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.inside-box {
  max-height: calc(100vh - 212px);
}

.dragas-box {
  strong {
    font-size: 73%;
    vertical-align: middle;
  }
}

.dragas {
  display: inline-block;

  span {
    font-size: 73%;
    color: black;
    background-color: $gray;
    @include border_radius(0.25rem 0 0 0.25rem);

    &:first-child {
      border-right: 1px solid white;
    }

    &:last-child {
      @include border_radius(0 0.25rem 0.25rem 0);
      border-left: 1px solid white;
    }

    &.red {
      background-color: $light-coral;
    }

    &.yellow {
      background-color: $topaz;
    }

    &.green {
      background-color: $phelcom-green;
    }

    &.no-info {
      border-color: #404040;
      border-radius: 99px;
      border-width: 1.5px;
      border-style: solid;
    }
  }

  &.os {
    display: none;
  }
}

.icon-phelcom-ai.phelcom-dragas {
  color: white;
}

.table {
  margin-bottom: 0;

  >tfoot>tr>td,
  >tfoot>tr>th,
  >thead>tr>td,
  >thead>tr>th,
  >tbody>tr>td,
  >tbody>tr>td .fa,
  >tbody>tr>th {
    vertical-align: middle;
  }
}

.table>tbody>tr>td {
  height: 46px !important;
}

.label.label-blue-phelcom {
  margin-right: 5px;
}

.no-btn {
  padding: 0;
  margin: 0 10px 0 0;

  &.favorite {
    position: relative;
    z-index: 0;

    &:after {
      @include border_radius(50%);
      @include transition(height 0.5s ease);
      content: "";
      display: block;
      opacity: 0;
      position: absolute;
      z-index: -1;
      background: none;
      box-sizing: border-box;
      transform: scale(0);
      transition-property: transform, opacity;
      bottom: -4.5px;
      left: -4.5px;
      right: -4.5px;
      top: -4.5px;
      width: 30px;
      height: 30px;
      background-color: rgba(32, 33, 36, 0.059);
    }

    .fa-star-o {
      color: grey;
    }

    .fa-star {
      @include animation(fadein .5s);
      color: #e5c04d;
    }

    &:hover,
    &:focus {
      &:after {
        border: none;
        box-shadow: none;
        opacity: 1;
        transform: scale(1);
      }

      .fa-star-o {
        @include scale(1.2);
        color: black;
      }
    }
  }
}

.fa-tint {
  color: red;
}

.fa-check-circle-o {
  color: green;
}

.fa{
  font-size: 22px !important;
}

.m-0 {
  font-size: 0.9em;

  i {
    vertical-align: top;
  }
}

.btn-load {
  position: fixed;
  bottom: 52px;
  left: 50%;
  padding: 10px 20px;
  transform: translateX(-50%);
  z-index: 100;

  &,
  &:hover,
  &:focus,
  &:active,
  &.active {
    background: white;
  }
}

@media (max-width: 991px) {
  .exam-list-header {
    padding: 10px;

    span {
      display: none;
    }

    >div {
      &:first-child {
        width: 100%;

        .btn-outline-secondary {
          height: 35px;
          padding: 10px 12px;
        }
      }
    }
  }

  .title-box {
    display: flex !important;
    justify-content: space-between;
    gap: 0;
    width: 100%;
    margin-bottom: 0 !important;

    .title {
      font-size: 18px !important;
      text-align: left;
      width: calc(100% - 54px);
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import { useExamStore } from '@/store/exam'
import { useExamFilterStore } from '@/store/examFilter'
import { watch } from 'vue'

import ExamAssignPatientOffcanvas from './ExamAssignPatientOffcanvas.vue'
// import ExamCreateNewModal from './ExamCreateNewModal.vue'
import ExamDownloadOffcanvas from './ExamDownloadOffcanvas.vue'
import ExamFilter from './ExamFilter.vue'
import ExamGridList from './ExamGridList.vue'
import ExamNewOffcanvas from './ExamNewOffcanvas.vue'
import ExamTableList from './ExamTableList.vue'

const IS_PRODUCTION = process.env.NODE_ENV == 'production'

export default {
  name: 'exam-list',
  components: {
    ExamAssignPatientOffcanvas,
    // ExamCreateNewModal,
    ExamDownloadOffcanvas,
    ExamFilter,
    ExamGridList,
    ExamNewOffcanvas,
    ExamTableList
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    tableExam: {
      items: [],
      total: 0,
      columns: []
    },
    perPage: 20,
    currentPage: 1,
    totalPages: 0,
    session: null,
    filter: {
      startDate: null,
      endDate: null,
      filterSelected: null,
      newRequest: false,
      examCurrentPage: null,
      scrollPosition: 0,
    },
    // customColumns: ["foo", "bar"], ?????
    dragasEyes: {
      os: null,
      od: null
    },
    showInputSearch: false,
    showFilterBox: false,
    showValue: null,
    showModalDownload: false,
    showOffcanvasNewExam: false,
    examImages: [],
    thumbDataPath: null,
    dataPath: null,
    tableType: null,
    showTips: true,
    showMutirao: false,
    examPreviousPage: 0,
    examNextPage: 0,
    loadedPages: new Set(),
  }),
  computed: {
    isCollapsed() {
      let element = document.querySelector('body')
      if (element.classList.contains('retract'))
        return true
    }
  },
  mounted() {
    this.$activeLink.value = 'exam'
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.showMutirao = this.session.features.includes('mutirao')
    if (this.session.collaborator.welcomeTips)
      this.showTips = false

    if (localStorage.getItem('examTableType')) {
      this.tableType = localStorage.getItem('examTableType')
    } else {
      this.tableType = 'grid'
    }

    const examFilterStore = useExamFilterStore();

    watch(() => examFilterStore.filter, (newFilterValue) => {
      if (newFilterValue) {
        this.doFilter(newFilterValue);
      }
    });

    if (examFilterStore.filter.examCurrentPage > 0) {
      this.examPreviousPage = examFilterStore.filter.examCurrentPage
      this.examNextPage = examFilterStore.filter.examCurrentPage
      this.currentPage = examFilterStore.filter.examCurrentPage
      this.loadedPages.add(this.currentPage)

      console.log('examFilterStore.filter.examCurrentPage', examFilterStore.filter.examCurrentPage);
      console.log('this.examPreviousPage', this.examPreviousPage, 'this.examNextPage', this.examNextPage);
    }
  },
  provide() {
    return {
      scrollToTop: (callback) => {
        this.scrollTopCallback = callback;
      }
    };
  },
  methods: {
    // onPageChange(page) {
    //   this.currentPage = page;
    //   this.session.examCurrentPage = page;
    //   this.filter.pagination = true
    //   this.filter.newRequest = false
    //   this.doFilter(this.filter);
    // },
    goTop() {
      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.filter.newRequest = true

      this.$nextTick(() => {
        if (this.filter.newRequest)
          this.debounce()
      })
    },
    debounce: _.debounce(function() {
      this.doFilter(this.filter)
    }, 250, { 'maxWait': 150 }),
    async fetchData() {
      this.alert.message = null;

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$examService.listWithExamdata();
        //let response = await this.$examService.list();
        // console.log('response fetch', response);
        this.loadTableData(response)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async doFilter(filter) {
      this.loadedPages = new Set()
      const examFilterStore = useExamFilterStore();
      this.alert.message = null;
      let params = {}

      console.log('entrou new request', examFilterStore.filter);
      if (filter) {
        this.filter = filter;
        params.startDate = this.filter.startDate
        params.endDate = this.filter.endDate
        params.statusFilter = this.filter.filterSelected

        if (filter.newRequest) {
          this.currentPage = 1
          this.examPreviousPage = 1
          this.examNextPage = 1
          this.filter.examCurrentPage = 0

          examFilterStore.refreshFilter(this.filter)
        }
      }

      params.page = 1

      if (examFilterStore.filter.examCurrentPage > 1) {
        this.currentPage = examFilterStore.filter.examCurrentPage
        params.page = examFilterStore.filter.examCurrentPage
      }

      this.loadedPages.add(params.page);

      this.loading = true
      NProgress.start()
      try {
        //let response = await this.$examService.filteredList(params);
        let response = await this.$examService.filteredListWithExamdata(params);

        if (response.totalCount == 0) {
          this.alert = this.$message.alert(this, 'request_notfound_gently', 'warning')
          NProgress.done(true)
          this.loading = false
          this.$nextTick(() => {
            this.tableExam = {
              items: [],
              total: 0,
              columns: []
            };
          })
          return
        }

        this.loadTableData(response, params.page)

        this.$nextTick(() => {
          this.filter.newRequest = false
        })
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async fetchMore(type) {
      if (this.loading || this.filter.newRequest)
        return

      console.log('check current page fetchMore', this.currentPage);
      this.alert.message = null;
      let params = {}

      if (Object.keys(this.filter).length > 0) {
        params.startDate = this.filter.startDate
        params.endDate = this.filter.endDate
        params.statusFilter = this.filter.filterSelected
      }

      let pageToLoad;
      if (type === 'top') {
        if (this.examPreviousPage <= 1) {
          console.log('Já está na primeira página.');
          return;
        }
        pageToLoad = --this.examPreviousPage;
      } else {
        pageToLoad = ++this.examNextPage;
        this.currentPage = pageToLoad
      }

      if (this.loadedPages.has(pageToLoad)) {
        console.log(`Página ${pageToLoad} já foi carregada.`);
        return;
      }
      this.loadedPages.add(pageToLoad);
      params.page = pageToLoad;

      console.log('this.tableExam.total', this.tableExam.total);
      console.log('this.tableExam.totalLoaded', this.tableExam.totalLoaded);
      console.log('this.tableExam.items.length', this.tableExam.items.length);

      this.loading = true
      NProgress.start()
      try {
        //let response = await this.$examService.filteredList(params);
        let response = await this.$examService.filteredListWithExamdata(params);

        if (response.totalCount == 0) {
          this.alert = this.$message.alert(this, 'request_notfound_gently', 'warning')
          NProgress.done(true)
          this.loading = false
          this.$nextTick(() => {
            this.tableExam = {
              items: [],
              total: 0,
              columns: []
            };
          })
          return
        }

        if (this.tableExam.items.length == response.totalCount) {
          // BRUNO: OMMITING FOR NOW...
          // this.$message.popup(this, 'request_end', 'warning', 'warning')
          NProgress.done(true)
          this.loading = false
          return
        }

        let result = type == 'top' ? response.result.reverse() : response.result

        result.forEach(async (item) => {
          item.pageOrigin = pageToLoad;
          item.status = this.$i18n.t(item.status);
          const modelVersion = this.$aiService.getModelVersion(item)
          if (this.showMutirao && modelVersion && modelVersion.includes('mcRAS')) {
            item.scoreResult = this.$aiService.processMCRASFromExam(item)
          } else {
            item.scoreResult = this.$aiService.alterationScore(item.metadata);
          }

          this.$nextTick(() => {
            if (type == 'top')
              this.tableExam.items.unshift(item);
            else
              this.tableExam.items.push(item);
          })
        })

        const maxPage = Math.max(...Array.from(this.loadedPages));
        const loadedItemCount = maxPage * 20;
        // const loadedItemCount = this.loadedPages.size * 20;
        this.tableExam.totalLoaded = loadedItemCount
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        this.loadedPages.delete(pageToLoad);
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async loadMoreMonths() {
      const examFilterStore = useExamFilterStore();

      examFilterStore.reset()

      console.log(this.filter);
      let startDateAux = this.subtractMonthsKeepingDay(this.filter.startDate, 3)
      let endDateAux = this.subtractMonthsKeepingDay(this.filter.endDate, 3)

      this.filter.startDate = this.$utils.formatDate(this, startDateAux)
      this.filter.endDate = this.$utils.formatDate(this, endDateAux)
      this.filter.newRequest = true
      this.filter.examCurrentPage = 0
      this.currentPage = 1
      this.examNextPage = 0
      this.examPreviousPage = 0

      examFilterStore.refreshFilter(this.filter)

      this.filter = examFilterStore.filter


      if (this.scrollTopCallback) {
        this.scrollTopCallback();
      }

      this.$nextTick(() => {
        this.doFilter(this.filter)
      })
    },
    subtractMonthsKeepingDay(dateString, months) {
      let originalDate = moment(dateString, this.$i18n.t('dateFormat'));
      let day = originalDate.date();

      let newDate = originalDate.subtract(months, 'month');

      if (newDate.date() !== day) {
        let maxDay = newDate.daysInMonth();
        newDate.date(Math.min(day, maxDay));
      }

      return newDate.toDate();
    },
    async fetchDataImage(examID) {
      this.loading = true
      NProgress.start()
      try {

        let response = await this.$examDataService.list({ id: examID });

        this.thumbDataPath = `${response.thumbDataPath}`
        this.dataPath = `${response.dataPath}`

        // TODO move to service
        if (!IS_PRODUCTION) {
          this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
          this.dataPath = `http://localhost:1337${response.dataPath}`
        }

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false

      return examImages
    },
    loadTableData(response, currentPage) {
      this.alert = {}
      response.result.forEach(async (item) => {
        try {
          item.status = this.$i18n.t(item.status);

          if (currentPage)
            item.pageOrigin = currentPage

          const modelVersion = this.$aiService.getModelVersion(item)
          if (this.showMutirao && modelVersion && modelVersion.includes('mcRAS')) {
            item.scoreResult = this.$aiService.processMCRASFromExam(item)
            return
          }
          item.scoreResult = this.$aiService.alterationScore(item.metadata);
        } catch (error) {
          item.scoreResult = 'error'
        }
      });

      this.thumbDataPath = `${response.thumbDataPath}`
      this.dataPath = `${response.dataPath}`

      // TODO move to service
      if (!IS_PRODUCTION) {
        this.thumbDataPath = `http://localhost:1337${response.thumbDataPath}`
        this.dataPath = `http://localhost:1337${response.dataPath}`
      }

      const maxPage = Math.max(...Array.from(this.loadedPages));
      const loadedItemCount = maxPage * 20;
      console.log('this.tableExam.totalLoaded', this.tableExam.totalLoaded);
      console.log('loadedItemCount', loadedItemCount);

      this.tableExam = {
        'items': response.result,
        'total': response.totalCount,
        'totalLoaded': loadedItemCount,
        'columns': [
          // {key:"favorite", name: '', type: 'custom', value: 'actions', width: '2%'},
          {key: 'name', name: this.$i18n.t('exam.name'), type: 'string', value: 'patient.fullName', width: '20%'},
          {key: 'captures', name: this.$i18n.t('exam.captures'), type: 'custom', value: 'captures', width: '20%'},
          {name: this.$i18n.t('exam.date'), type: 'date', value: 'date', width: '20%'},
          {key: 'content', name: this.$i18n.t('exam.content'), type: 'custom', value: 'actions', width: '20%'},
          {key: 'ai', name: this.$i18n.t('exam.statusAI'), type: 'custom', value: 'status', width: '20%'}
        ]
      };

      this.totalPages = Math.ceil(response.totalCount / this.perPage);

      if (response.result.length === 0) {
        this.tableExam = {};
        this.alert = this.$message.alert(this, 'request_notfound_gently', 'warning')
        // this.$message.popup(this, 'request_notfound', 'operationWarning', 'warn')
      }
    },
    examSelected(values) {

      if (!values) {
        return
      }
      let item = values.item

      if (!item) {
        return
      }
      let data = null

      if (!_.isEmpty(this.filter)) {
        this.filter.examCurrentPage = item.pageOrigin ? item.pageOrigin : 1

        const examFilterStore = useExamFilterStore()
        examFilterStore.refreshFilter(this.filter)

        const startDate = moment(this.filter.startDate, this.$i18n.t('dateFormat'))
        const endDate = moment(this.filter.endDate, this.$i18n.t('dateFormat'))

        data = this.$utils.dataQuery({
          startDate: startDate.format(),
          endDate: endDate.format(),
          filterSelected: this.filter.filterSelected
        })
      }
      if (values.commandPressed){
        // Resolve the route
        const routeData = this.$router.resolve({
            name: 'exam-show',
            params:  { examID: item.id },
            query: { tab: 'images', data: data }
        });

        // Open the resolved route in a new tab
        window.open(routeData.href, '_blank');
      } else{
        this.$router.push({ name: 'exam-show', params: { examID: item.id }, query: { tab: 'images', data: data } })
      }
    },
    formatDate(date) {
      return moment(date).valueOf();
    },
    checkExamStatus(value) {
      return value == 'closed' ? 'label-closed' : 'label-open'
    },
    doDownload(exam) {
      this.alert.message = null;
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    useHeader(exam) {
      return this.$aiService.mcRASHeader(exam) != ""
    },
    aiHeader(exam) {
      return this.$aiService.mcRASHeader(exam)
    },
    activeAiClasses(exam) {
      return this.$aiService.mcRASClasses(exam)
    },
    doFavorite(item) {
      item.favorite = true
    },
    examHasMCRAS(exam) {
      return this.$aiService.hasMCRAS(exam)
    },
    examHasDragas(item) {
      let res = false
      try {
        let data = JSON.parse(item.metadata)
        if (data.dragas)
          res = true
      } catch (err) {
        // do nothing
      }
      return res
    },
    getDragas(exam) {

      let dragas = this.$aiService.dragasClasses(exam)

      return dragas
    },
    openModalDownload() {
      this.showModalDownload = true

      setTimeout(function () {
        let modaldownload = document.getElementById("modaldownload");
        new bootstrap.Modal(modaldownload).show();
      }, 100);
    },
    openOffCanvasNewExam() {
      this.showOffcanvasNewExam = true

      this.$nextTick(() => {
        this.$refs.examNewOffcanvas.show()
      })
    },
    async openOffcanvasExamDownload() {
      const ok = await this.$refs.examDownloadOffcanvas.show({
        title: this.$i18n.t('exam.examsDownload'),
        // text: this.$i18n.t('exam.confirmExamRemoval'),
        okButton: 'Download',
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('cancel'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        // this.doDelete()
      }
    },
    closeModal() {
      this.showOffcanvasNewExam = false
      this.fetchData()
    },
    async openAssignOffcanvas(item) {
      const ok = await this.$refs.examAssignPatientOffcanvas.show({
        exam: item
      })
      if (ok) {
      }
    },
    closeAssignOffCanvas() {
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    setTableLocalStorage(value) {
      this.tableType = value
      localStorage.setItem('examTableType', value);
    },
    closeAndIncrementTipsCount() {
      this.showTips = false
      const userStore = useUserStore()
      userStore.incrementTipsCount(1)
    },
    closeOffCanvasDoFilter(){
      this.$nextTick(() => {
        this.fetchData()
      })
    },
  },
  // unmounted() {
  //   this.alert.message = null;
  //   this.tableExam = [];
  // }
}
</script>
