<template>
  <BaseInfiniteGridTable class="no-border" v-if="table_exam && session" :tabledata="table_exam"
    @item-opened="$emit('item-opened')" @loadmore="$emit('loadmore')">
    <template v-slot:column_name="{ item }">
      <div class="patient-box" :class="{ 'anonymous': isJokerPatient(item) }">
        <p>
          <IconCalendar />
          <span>{{ formatDate(item.date) }}</span>

          <span v-if="showMutirao" style="display: flex; align-items: center;">
            <i v-show="item.referred > 0" class="fa" :class="item.referred == 1 ? 'fa-tint' : 'fa-check-circle-o'"
              aria-hidden="true"></i>
          </span>
        </p>
        <a class="btn btn-secondary" href="#"
          @click.prevent.stop="isJokerPatient(item) ? $emit('do-assign', item) : toPatientDetails(item)">
          <!-- <IconPatients/> -->
          <span>{{ isJokerPatient(item) ? $t('noPatientSelected') : item.patient.fullName }}</span>
        </a>
      </div>
    </template>

    <template v-slot:column_captures="{ item }">
      <lightgallery-vue class="image-info-box grid-wrap-between ease-all" :ref="`lGallery_${item.uuid}`"
        :id="`lightgallery_${item.uuid}`" :key="item.uuid" :settings="lightGallerySettings"
        :onAfterOpen="extraCustomization" v-if="item.examImages.length > 0" @click.stop.prevent="prevent">
        <template v-for="(item, index) in item.examImages" :key="index">
          <a v-if="allowedImages(item.type) && index < 8" class="thumbnail-box loading-eye" onclick="return false" :class="item.type"
            :href="data_path + '/' + item.name" :data-test="`anchorImage_${index}`">
            <img class="lg-selector ease-all" :src="thumb_path + '/' + item.name" alt="" @load="removeLoading($event)" />
          </a>
        </template>
      </lightgallery-vue>
    </template>

    <template v-slot:column_content="{ item }">
      <div class="custom-box">
        <div class="btn-group" role="group" aria-label="Default button group" v-if="item.hasImage || item.hasReport">
          <button type="button" class="btn btn-outline-secondary" :disabled="isJokerPatient(item) ? true : !item.hasImage"
            :data-title="$t('tooltips.download')" :data-test="`btnExamHasImage_${item.uuid}`" @click.stop.prevent="doDownloadExam(item)">
            <!-- <IconImage /> -->
            <IconDownload />
            {{ $t('tooltips.download') }}
          </button>
          <button type="button" class="btn btn-outline-secondary" :disabled="isJokerPatient(item)"
            :data-title="$t('tooltips.report')" :data-test="`btnExamHasReport_${item.uuid}`" @click.stop.prevent="checkDownloadOrRoute(item)">
            <IconPdf />
            {{ $t('tooltips.report') }}
          </button>
        </div>


        <div class="ai-box" style="align-self: flex-end;" v-if="item.scoreResult" :data-title="$t('exam.iaText')">
          <p>AI</p>
          <span :class="item.scoreResult.odStatus ?? ''"></span>
          <span :class="item.scoreResult.osStatus ?? ''"></span>
        </div>
        <div class="alteration-list" v-if="showMutirao && item?.scoreResult?.alterationText">
          <template v-for="text of item.scoreResult.alterationText">
            <b v-if="text === 'clinicConfig.mcRAS_c1'">
              {{ $t(text) }}
            </b>
            <span v-else>
              {{ $t(text) }}
            </span>
          </template>
        </div>

      </div>

    </template>

    <!-- <template v-slot:column_ai="{item}">

    </template> -->
  </BaseInfiniteGridTable>
</template>

<style lang="scss" scoped>
.fa {
  font-size: 22px !important;
  align-self: center;
  color: var(--Brand-Primary, #1B294A);
}

.image-info-box {
  gap: 10px;

  &.grid-wrap-between {
    grid-template-columns: repeat(auto-fill, 32.7%) !important;
  }

  .thumbnail-box {
    margin: 0;

    img {
      aspect-ratio: 87 / 74;
      // aspect-ratio:                   16 / 13;
    }

    &:hover,
    &:focus {
      img {
        // border:                       5px solid white !important;
      }
    }
  }
}


.alteration-list {
  display: flex;
  flex-direction: column;
  color: var(--Brand-Primary);
  font-size: 0.9em;
  align-self: center;
  gap: 5px;

  b {
    order: 0;

    &:last-child {
      order: 1;
    }
  }

  span {
    order: 2;
  }
}

.rectract {
  .image-info-box.grid-wrap-between {
    grid-template-columns: repeat(auto-fill, 24%) !important;
  }
}

@media (max-width: 500px) {

  .retract .image-info-box.grid-wrap-between,
  .image-info-box.grid-wrap-between {
    grid-template-columns: repeat(auto-fill, 100%) !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 991px) {

  .retract .image-info-box.grid-wrap-between,
  .image-info-box.grid-wrap-between {
    grid-template-columns: repeat(auto-fill, 48%) !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .image-info-box.grid-wrap-between {
    grid-template-columns: repeat(auto-fill, 32%) !important;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from '@/assets/dependencies/lightGallery/plugins/zoom';

export default {
  name: 'ExamGridList',
  props: ['table_exam', 'thumb_path', 'data_path'],
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: null,
    lightGallerySettings: {
      showZoomInOutIcons: true,
      actualSize: false,
      autoplayVideoOnSlide: true,
      speed: 300,
      licenseKey: 'CE440F6C-34CE4C2A-86065648-48895C83',
      plugins: [],
      showMutirao: false
    },
    showMutirao: false
  }),
  created() {
    this.lightGallerySettings.plugins = [lgAutoplay, lgFullscreen, lgThumbnail, lgZoom]
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.showMutirao = this.session.features.includes('mutirao')
  },
  methods: {
    extraCustomization() {
      let self = this

      $(".lg-download").on("click", function (event) {
        event.preventDefault();
        console.log('this.href: ',this.href)
        let examDataUUID = this.href.split('/').pop()
        console.log('this.href: ',examDataUUID)
        let item = { uuid: examDataUUID }
        self.doDownload(item)
      })

      $('.lg-fullscreen.lg-icon').click();
    },
    async doDownload(item) {
      this.loading = true
      NProgress.start()
      try {
        let params = {}
        if (item.id)
          params['id'] = item.id

        if (item.uuid)
          params['uuid'] = item.uuid

        let response = await this.$examDataService.download(params)

        fetch(response.downloadURL)
          .then(res => res.blob())
          .then(blob => {
            let objectURL = URL.createObjectURL(blob);
            let el = document.createElement('a');
            el.href = objectURL;
            el.download = `${item.uuid}.jpeg`;
            el.click();
            URL.revokeObjectURL(objectURL);
          });
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    removeLoading(event) {
      let parentNode = event.target.parentElement.classList;
      let currentlyNode = event.target.classList;

      this.$nextTick(() => {
        currentlyNode.add('loaded')
        parentNode.remove('loading-eye')
      });
    },
    allowedImages(image) {
      // console.log('type image', image);
      return !image.endsWith('VIDEO')
      // return image.endsWith('COLOR') ||
      //   image.endsWith('REDFREE') ||
      //   image.endsWith('redfree') ||
      //   image.endsWith('TEXTURE') ||
      //   image.endsWith('HEATMAP') ||
      //   image.endsWith('heatmap') ||
      //   image.endsWith('image')
    },
    prevent() {
      // function to avoid click to expand and enter in exam details
      console.log('prevent');
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateTimeFormat'))
    },
    toPatientDetails(item) {
      this.$router.push({ name: 'patient-show', params: { patientID: item.patient.id }, query: { tab: 'data' } })
    },
    isJokerPatient(item) {
      return item.patient.uuid == this.session.currentClinic.jokerPatientUuid
    },
    doDownloadExam(exam){
      try {
        NProgress.start()
        this.$examService.download(exam.id)
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
    },
    async checkDownloadOrRoute(item){
        if (item.hasReport){
            await this.doDownloadPDF(item)
        }else {
            this.$router.push({ name: 'exam-show', params: { examID: item.id }, query: { tab: 'report' } })
        }

    },
    async doDownloadPDF(item){
      let params = {
        id: item.id,
      }
      try {
        let response = await this.$reportService.getList(params);

        if (response.result.length > 0) {
          let theReport = response.result[response.result.length - 1]
          let params = {
            id: theReport.id
          }
          this.$reportService.generateReport(params);
        }

      } catch(err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }

    }
  }
}
</script>
