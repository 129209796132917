<template>
  <div class="clinic-form-box">
    <div class="row justify-content-md-start">
      <div class="col-md-4">
        <ul class="anchor-menu">
          <li :class="{'active': activeAnchor === 'general'}">
            <a @click="setActiveAnchor('general')">{{ $t('createClinic.generalData') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'localization'}">
            <a @click="setActiveAnchor('localization')">{{ $t('createClinic.location') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'configuration'}">
            <a @click="setActiveAnchor('configuration')">{{ $t('createClinic.configuration') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'locale'}">
            <a @click="setActiveAnchor('locale')">{{ $t('createClinic.language') }}</a>
          </li>
          <li :class="{'active': activeAnchor === 'security'}">
            <a @click="setActiveAnchor('security')">{{ $t('createClinic.security') }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-8 offset-md-4 scrollbar" id="sectionsBox" ref="scrollContainer" v-if="clinic">
        <section id="general">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.generalData') }}</h3>
          <div class="form-floating">
            <vee-field name="name" v-model="clinic.name" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="name" id="name" :placeholder="$t('createClinic.name')" data-test="inputClinicName" autofocus >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="name">{{ $t('createClinic.name') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="fantasyName" v-model="clinic.fantasyName" rules="required" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="fantasyName" id="fantasyName" :placeholder="$t('createClinic.nameExhibition')" data-test="inputClinicFantasyName" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="fantasyName">{{ $t('createClinic.nameExhibition') }}*</label>
          </div>
          <div class="form-floating">
            <vee-field name="document" v-model="clinic.document" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="document" id="document" v-maska
                     :data-maska="$t('inputMask.clinicDocument')" :placeholder="$t('createClinic.document')" data-test="inputClinicDocument" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="document">{{ $t('createClinic.document') }}</label>
          </div>
          <!-- end verify -->
          <div class="form-floating">
            <vee-field name="email" v-model="clinic.email" rules="email" v-slot="{ field, errorMessage }">
              <input v-bind="field" type="text" class="form-control" ref="email" id="email" :placeholder="$t('createClinic.email')" data-test="inputClinicEmail" >
              <span class="vee-error">{{ errorMessage }}</span>
            </vee-field>
            <label for="email">{{ $t('createClinic.email') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone1" v-model="clinic.telephone1" :placeholder="$t('createClinic.telephone1')" data-test="inputClinicPhone1" >
            <label for="telephone1">{{ $t('createClinic.telephone1') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="telephone2" v-model="clinic.telephone2" :placeholder="$t('createClinic.telephone2')" data-test="inputClinicPhone2" >
            <label for="telephone2">{{ $t('createClinic.telephone2') }}</label>
          </div>

          <div class="form-floating v-select-floating" v-if="is_creating">
            <v-select
              class="v-select form filter"
              v-model="parentClinicSelected"
              data-test="vSelectClinic"
              :placeholder="$t('createClinic.parentClinic')"
              :reduce="x => x.value"
              :options="parent_clinic_options"
              @option:selected="emitParentClinic">
            </v-select>
            <label for="title">{{ $t('createClinic.parentClinic') }}</label>
          </div>
        </section>

        <section id="localization">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.location') }}</h3>
          <div class="form-floating">
            <input type="text" class="form-control" id="address" v-model="clinic.address" :placeholder="$t('createClinic.address')" data-test="inputClinicAddress" >
            <label for="address">{{ $t('createClinic.address') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="number" v-model="clinic.addressNumber" :placeholder="$t('createClinic.number')" data-test="inputClinicNumber" >
            <label for="number">{{ $t('createClinic.number') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="district" v-model="clinic.district" :placeholder="$t('createClinic.district')" data-test="inputClinicDistrict" >
            <label for="district">{{ $t('createClinic.district') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="city" v-model="clinic.city" :placeholder="$t('createClinic.city')" data-test="inputClinicCity" >
            <label for="city">{{ $t('createClinic.city') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="state" v-model="clinic.state" :placeholder="$t('createClinic.state')" data-test="inputClinicState" >
            <label for="state">{{ $t('createClinic.state') }}</label>
          </div>
          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form"
              id="countrySelected"
              :placeholder="$t('createClinic.country')"
              v-model="countrySelected"
              data-test="vSelectCountry"
              :reduce="x => x.value"
              :options="countryOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="countrySelected">{{ $t('createClinic.country') }}</label>
          </div>
        </section>

        <section id="configuration">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.configuration') }}</h3>
          <div class="form-floating v-select-floating">
            <v-select
              label="label"
              class="v-select form"
              id="mandatoryDocument"
              :placeholder="$t('createClinic.mandatoryDocument')"
              v-model="mandatoryDocument"
              data-test="vSelectMandatoryDocument"
              :reduce="x => x.value"
              :options="configOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="mandatoryDocument">{{ $t('createClinic.mandatoryDocument') }}</label>
          </div>
        </section>

        <section id="locale">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.language') }}</h3>
          <div class="form-floating v-select-floating">
            <v-select
              class="v-select form"
              id="languageSelected"
              :placeholder="$t('createClinic.language')"
              :reduce="x => x.value"
              v-model="languageSelected"
              data-test="vSelectLanguage"
              :options="languageOptions"
              @option:selected="emitOptions">
            </v-select>
            <label for="languageSelected">{{ $t('createClinic.language') }}</label>
          </div>
        </section>

        <section id="security">
          <h3 class="sub-title" ref="targets">{{ $t('createClinic.security') }}</h3>

          <div class="form-floating v-select-floating">
            <v-select
              label="label"
              class="v-select form"
              id="mfaMode"
              :placeholder="$t('createClinic.mfaMode')"
              v-model="mfaMode"
              data-test="vSelectMfa"
              :reduce="x => x.value"
              :options="mfaOptions"
              @option:selected="mfaModeChanged">
            </v-select>
            <label for="mfaMode">{{ $t('createClinic.mfaMode') }}</label>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sub-title {
  color:                         var(--Shades-60, #727272);
  font-size:                     16px;
  font-weight:                   500;
  line-height:                   normal;
  letter-spacing:                3.2px;
  text-transform:                uppercase;
  text-overflow:                 ellipsis;
  white-space:                   nowrap;
  overflow:                      hidden;
  border:                        0;
  margin:                        0;
}

// .anchor-menu {
//   display:                       flex;
//   flex-direction:                column;
//   align-items:                   flex-start;
//   gap:                           20px;
//   padding:                       0;
//
//   li {
//     display:                     flex;
//     width:                       auto;
//
//     a {
//       @include                   border_radius(99px);
//       color:                     var(--Shades-60, #727272);
//       font-size:                 var(--font-size-14);
//       font-weight:               500;
//       line-height:               normal;
//       letter-spacing:            2.8px;
//       text-decoration:           none;
//       text-transform:            uppercase;
//       text-overflow:             ellipsis;
//       overflow:                  hidden;
//       padding:                   10px 20px;
//       background:                var(--Shades-10, #FAFAFA);
//       border:                    1px solid var(--Shades-30, #D8D8D8);
//     }
//
//     &:target,
//     &:hover,
//     &:focus,
//     &:active,
//     &.active {
//       a {
//         color:                   var(--Support-Success, #81BAA7);
//         border:                  1px solid var(--Support-Success, #81BAA7);
//       }
//     }
//   }
// }

.col-md-4 {
  position:                      fixed;
  display:                       flex;
  justify-content:               center;
}

.retract {
  .col-md-4 {
    justify-content:             flex-end;
  }
}

.col-md-8 section,
.col-lg-6 {
  display:                       flex;
  gap:                           19px;
  flex-direction:                column;
}

.col-md-8 {
  overflow-y:                    auto;
  height:                        100%;
  max-height:                    calc(100vh - 324px);
  // max-height:                    calc(100vh - 348px);
  // max-height:                    calc(100vh - 165px);
  // max-height:                    calc(100vh - 217px);

  section {
    max-width:                   550px;
    width:                       100%;
    padding-bottom:              33px;

    &:last-child {
      padding-bottom:            4rem;
    }
  }
}

.clinic-form-box .dropdown-menu.show,
.vee-error {
  width:                         100% !important;
}

@media (max-width: 991px) {
  .col-md-4 {
    position:                    relative;
    justify-content:             flex-start;
  }

  .anchor-menu {
    flex-direction:              row;
    align-items:                 flex-start;
    gap:                         20px;
    overflow-x:                  scroll;
    padding:                     0;
    margin-top:                  -10px;
    background:                  var(--Shades-10, #FAFAFA);
    border-bottom:               1px solid var(--Shades-30, #D8D8D8);

    &::-webkit-scrollbar {
      display:                   none;
    }

    li {
      a {
        @include                 border_radius(0);
        font-size:               12px;
        white-space:             nowrap;
        padding:                 15px 20px;
        background:              transparent;
        border:                  0;
      }

      &:target,
      &:hover,
      &:focus,
      &:active,
      &.active {
        a {
          border:               0;
          border-bottom:        2px solid var(--Support-Success, #81BAA7);
        }
      }
    }
  }

  .sub-title {
    font-size:                  14px;
  }

  .col-md-8 {
    max-height:                  calc(100dvh - 308px) !important;
    padding:                     0 15px 0 24px;
  }
}

@media (max-width: 1500px) {
  .col-md-8 {
    max-height:                  calc(100vh - 318px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .col-md-8 {
    max-height:                  calc(100vh - 303px);
  }
}
</style>

<script>
export default {
  name: 'ClinicFormData',
  props: ['clinic', 'disabled', 'is_creating', 'parent_clinic_options'],
  data: () => ({
    countryOptions: [],
    configOptions: [],
    mfaOptions: [],
    languageOptions: [],
    countrySelected: '',
    mandatoryDocument: 'none',
    languageSelected: '',

    mfaMode: null,
    security: {},
    activeAnchor: 'general',
    // parentClinicOptions: [
    //   {value: null, label: 'None'}
    // ],
    clinicTemplate: null,
    parentClinicSelected: null,
  }),
  watch: {
    disabled: function(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.name.focus()
        })
      }
    },
  },
  mounted() {
    this.configOptions = [
      {value: 'none', label: this.$i18n.t('none')},
      {value: 'mrn', label: this.$i18n.t('mrn')},
      {value: 'document0', label: this.$i18n.t('document0')},
      {value: 'document1', label: this.$i18n.t('document1')},
      {value: 'document2', label: this.$i18n.t('document2')},
      {value: 'document3', label: this.$i18n.t('document3')},
    ];

    this.mfaOptions = [
      {value: 'disabled', label: this.$i18n.t('mfa.disabled')},
      {value: 'optional', label: this.$i18n.t('mfa.optional')},
      {value: 'mandatory', label: this.$i18n.t('mfa.mandatory')}
    ]

    this.languageOptions = [
      {value: 'pt-br', label: this.$i18n.t('locales.pt-br')},
      {value: 'en', label: this.$i18n.t('locales.en')},
      {value: 'es', label: this.$i18n.t('locales.es')},
    ];

    this.setLocaleConfig()

    let security = this.$clinicService.getSecurityConfiguration(this.clinic)
    this.mfaMode = security.mfaMode

    const divElement = document.getElementById('sectionsBox')
    divElement.addEventListener('scroll', this.updateActiveAnchor);
    this.updateActiveAnchor(); // Initialize the active section
  },
  beforeUnmount() {
    const divElement = document.getElementById('sectionsBox')
    divElement.removeEventListener('scroll', this.updateActiveAnchor);
  },
  methods: {
    setActiveAnchor(anchor) {
      this.activeAnchor = anchor;
      this.$nextTick(() => {
        const headerHeight = 62;
        const element = document.getElementById(anchor);
        const container = this.$refs.scrollContainer;

        if (element && container) {
          const elementPosition = element.offsetTop;
          const offsetPosition = elementPosition - headerHeight;

          container.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      });
    },
    async setLocaleConfig() {
      let locale = this.clinic.locale

      const countryCodeList = await this.$utils.countryCodeList(locale)

      if (locale) {
        for (let code of countryCodeList) {
          this.countryOptions.push({
            value: code,
            label: this.$i18n.t(`country.${code}`)
          })
        }
      }

      this.countrySelected = this.clinic.country
      this.mandatoryDocument = this.clinic.mandatoryDocument
      this.languageSelected = this.clinic.locale
    },
    emitOptions() {
      let options = {
        country: this.countrySelected,
        mandatoryDocument: this.mandatoryDocument,
        locale: this.languageSelected,
      }

      this.$nextTick(() => {
        this.$emit('option-selected', options)
      })
    },
    emitParentClinic() {
      this.$emit('parent-changed', {
        parentClinic: this.parentClinicSelected
      })
    },
    mfaModeChanged() {
      this.$emit('security-changed', {
        mfaMode: this.mfaMode,
      })
    },
    updateActiveAnchor() {
      const sections = ['general', 'localization', 'configuration', 'locale', 'security'];
      const divElement = document.getElementById('sectionsBox')
      const divHeight = divElement.offsetHeight
      let currentActive;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (!element) continue;

        const rect = element.getBoundingClientRect();
        if (rect.top <= divHeight / 2 && rect.bottom >= divHeight / 2) {
          currentActive = section;
          break;
        }
      }

      if (currentActive && currentActive !== this.activeAnchor) {
        this.activeAnchor = currentActive;
      }
    }
  },
}
</script>
