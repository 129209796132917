import { defineStore } from 'pinia';

export const useExamFilterStore = defineStore('examFilter', {
  state: () => ({
    filter: {
      startDate: null,
      endDate: null,
      filterSelected: null,
      newRequest: false,
      examCurrentPage: 0,
      scrollPosition: 0,
    },
  }),
  actions: {
    refreshFilter(newFilterValue) {
      console.log('newFilterValue newFilterValue newFilterValue', newFilterValue);
      this.filter = newFilterValue;
    },

    reset(resetScrollPosition) {
      this.filter = {
        startDate: null,
        endDate: null,
        filterSelected: null,
        newRequest: false,
        examCurrentPage: 0
      }

      if (resetScrollPosition)
        this.filter.scrollPosition = 0
    }
  },
});
