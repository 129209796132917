import { defineStore } from 'pinia';

export const useExamStore = defineStore('exam', {
  state: () => ({
    exams: {
      result: []
    },
    currentPage: 1,
    totalExams: 0,
    scrollPosition: 0,
  }),
  actions: {
    // loadPage(page) {
    //
    // },
    resetExams() {
      this.exams = {
        result: []
      };
      this.currentPage = 1;
      this.scrollPosition = 0;
    },
  },
});
