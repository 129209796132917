<template>
  <div class="" v-loader.opacity.tab="loading">
    <div class="alert" :class="alert.class" role="alert" v-if="alert.message">
      <span v-html="alert.message"></span>
      <button type="button" class="btn-close" aria-label="Close" @click="alert.message = null"></button>
    </div>

    <div class="row justify-content-md-start" v-if="userData.id">
      <div class="col-md-4">
      </div>

      <div class="col-md-8 offset-md-4 scrollbar">
        <section>
          <!-- <div class="form-floating">
            <div class="form-check form-switch">
              <label class="form-check-label" for="activeUser">{{ $t('user.activeUser') }}</label>
              <input class="form-check-input" type="checkbox" role="switch" id="activeUser" v-model="userData.activeUser" data-test="checkboxActiveUser">
            </div>
          </div> -->
          <div class="form-floating">
            <input type="text" class="form-control" name="userData[firstName]" ref="firstName" id="firstName"
                   v-model="userData.collaborator.firstName" :placeholder="$t('user.firstName')" data-test="inputUserFirstName" :disabled="!isCurrentUser">
            <label for="firstName">{{ $t('user.firstName') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="lastName" name="userData[lastName]" v-model="userData.collaborator.lastName" data-test="inputUserLastName" :placeholder="$t('user.lastName')" :disabled="!isCurrentUser">
            <label for="lastName">{{ $t('user.lastName') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="email" name="userData[email]" v-model="userData.email" disabled data-test="inputUserEmail" :placeholder="$t('user.email')">
            <label for="email">{{ $t('user.email') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="npi" name="userData[npi]" v-model="userData.collaborator.npi" data-test="inputUserNpi" :placeholder="$t('user.npi')">
            <label for="npi">{{ $t('user.npi') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="document" name="userData[document]" v-model="userData.collaborator.document" data-test="inputUserDocument" :placeholder="$t('user.document')">
            <label for="document">{{ $t('user.document') }}</label>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" id="permissions" name="userData[permissions]" :value="$t(`${userData.permissions}`)" data-test="inputUserPermissions" :placeholder="$t('user.permissions')" disabled>
            <label for="permissions">{{ $t('user.permissions') }}</label>
          </div>
          <div class="form-check disabled">
            <BaseSquareCheckBox :checkbox_id="'mfa'" :item="'mfa'" @checked=""/>
            <!-- <input class="form-check-input" type="checkbox" value="" id="glaucoma" v-model="ophthalmic.glaucoma" data-test="checkboxOphtalmicGlaucoma" :disabled="disabled"> -->
            <label class="form-check-label" for="mfa">
              {{ $t('user.mfa') }}
            </label>
          </div>
          <!-- <div class="form-group d-block">
            <label for="document">{{ $t('user.permissions') }}</label>
            <span class="label label-success">{{ $t(`${user.permissions}`) }}</span>
            <button id="changePermission" type="button" class="btn btn-danger float-end" @click="openModalPermissions" data-test="btnOpenModalPermissions" v-show="session.isPowerfull && !isCurrentUser">{{ $t('user.changePermission') }}</button>
          </div> -->
          <!-- <div class="form-group">
            <label for="mfa">{{ $t('user.mfa') }}</label>
            <span class="label" :class="mfaStatus(user)">MFA</span>
            <button class="btn btn-info" v-if="isCurrentUser" data-test="btnMfaSetup" @click="mfaSetup()">{{$t('user.mfaSetup')}}</button>
          </div> -->
        </section>
      </div>

      <div class="action-bar" v-if="userData.id">
        <button class="btn btn-success" type="button" name="button" data-test="btnUpdate" v-if="hasFormChanged && isCurrentUser" @click="doUpdate">
          <span>{{ $t('user.modify') }}</span>
        </button>
        <button class="btn btn-secondary" type="button" name="button" data-test="btnOpenModalSignature" @click="openSignatureModal()" v-if="isCurrentUser">
          <span>{{ $t('user.signature') }}</span>
        </button>
        <button class="btn btn-secondary" type="button" name="button" data-test="btnOpenModalPhoto" @click="openChangePhotoModal()" v-if="isCurrentUser && false">
          <span>{{ $t('user.changePhoto') }}</span>
        </button>
        <button class="btn btn-secondary" type="button" name="button" data-test="btnOpenModalPassword" @click="openChangePassword()" v-if="isCurrentUser">
          <span>{{ $t('user.changePassword') }}</span>
        </button>
        <button class="btn btn-secondary" type="button" name="button" data-test="btnOpenModalPin" @click="openChangePin()" v-if="isCurrentUser">
          <span>{{ $t('user.changePIN') }}</span>
        </button>
        <button id="changePermission" type="button" class="btn btn-secondary" @click="openModalPermissions" data-test="btnOpenModalPermissions" v-if="session.isPowerfull && !isCurrentUser">
          {{ $t('user.changePermission') }}
        </button>
        <button class="btn btn-secondary" type="button" data-test="btnMfaSetup" @click="mfaSetup()" v-if="isCurrentUser">
          {{ $t('user.mfaSetup') }}
        </button>
        <button class="btn btn-outline-danger" type="button" data-test="btnMfaSetup" @click="openModalDelete(userData.id)" v-if="session.isPowerfull">
          {{ $t('remove') }}
        </button>
      </div>
    </div>

    <UserChangePinOffcanvas :offcanvas_id="'userChangePin'" ref="userChangePinOffcanvas"/>

    <UserChangePasswordOffcanvas :offcanvas_id="'userChangePassword'" ref="userChangePasswordOffcanvas"/>

    <UserPermissionsOffcanvas :offcanvas_id="'userPermissionsOffcanvas'" :user="userData" ref="userPermissionsOffcanvas"/>

    <BaseCropperOffcanvas :offcanvas_id="'userData'" ref="offcanvasCropper"/>

    <BaseGenericOffcanvas :offcanvas_id="'offcanvasGeneric'" ref="offcanvasGeneric"/>
  </div>
</template>

<style lang="scss" scoped>
.action-bar {
  display:                           flex;
  justify-content:                   flex-start;
  align-items:                       center;
  gap:                               10px;
  height:                            64px;
  padding:                           10px 20px;
  border-top:                        1px solid var(--Shades-30);
  .btn {
    @include                         border_radius(99px);
    font-size:                       var(--font-size-12);
    font-weight:                     700;
    letter-spacing:                  3.64px;
    text-transform:                  uppercase;
    height:                          44px;
    padding:                         15px 20px;
  }

  .btn-success {
    color:                           var(--Shades-W);
    background:                      var(--Brand-Secondary);
    border:                          1px solid var(--Brand-Secondary);
  }

  .btn-secondary {
    color:                           var(--Brand-Primary);
    background:                      var(--Shades-10);
    border:                          1px solid var(--Shades-10);
  }

  .btn-outline-danger {
    color:                           var(--Complementary-Orange, #D7541C);
    background:                      var(--W, #FFF);
    border:                          1px solid #F1C3AF;
  }
}

.inside-menu {
  display:                           flex;
  flex-direction:                    column;
  align-items:                       flex-start;
  gap:                               20px;
  padding:                           0;
  li {
    display:                         flex;
    width:                           auto;
    button {
      @include                       border_radius(99px);
      color:                         var(--Shades-60, #727272);
      font-size:                     var(--font-size-14);
      font-weight:                   500;
      line-height:                   normal;
      letter-spacing:                2.8px;
      text-decoration:               none;
      text-transform:                uppercase;
      text-overflow:                 ellipsis;
      overflow:                      hidden;
      padding:                       10px 20px;
      background:                    var(--Shades-10, #FAFAFA);
      border:                        1px solid var(--Shades-30, #D8D8D8);
    }

    &:target,
    &:hover,
    &:focus,
    &:active,
    &.active {
      a {
        color:                       var(--Support-Success, #81BAA7);
        border:                      1px solid var(--Support-Success, #81BAA7);
      }
    }
  }
}

.col-md-4 {
  position:                          fixed;
  display:                           flex;
  justify-content:                   center;
}

.retract {
  .col-md-4 {
    justify-content:                 flex-end;
  }
}

.col-lg-6 {
  padding-bottom:                    33px;
}

.col-md-8 section,
.col-lg-6 {
  display:                           flex;
  gap:                               19px;
  flex-direction:                    column;
}

.col-md-8 {
  overflow-y:                        auto;
  height:                            100%;
  min-height:                        calc(100vh - 309px);
  max-height:                        calc(100vh - 328px);
  // max-height:                        calc(100vh - 165px);
  // max-height:                        calc(100vh - 217px);

  section {
    max-width:                       550px;
    width:                           100%;

    &:last-child {
      padding-bottom:                4rem;
    }
  }
}

#permissions {
  text-transform:                    capitalize !important;
}

@media (max-width: 991px) {
  .col-md-4 {
    position:                        relative;
    justify-content:                 flex-start;
  }

  .anchor-menu {
    flex-direction:                  row;
    align-items:                     flex-start;
    gap:                             20px;
    overflow-x:                      scroll;
    padding:                         0;
    margin-top:                      -10px;
    background:                      var(--Shades-10, #FAFAFA);
    border-bottom:                   1px solid var(--Shades-30, #D8D8D8);

    &::-webkit-scrollbar {
      display:                       none;
    }

    li {
      a {
        @include                     border_radius(0);
        font-size:                   12px;
        white-space:                 nowrap;
        padding:                     15px 20px;
        background:                  transparent;
        border:                      0;
      }

      &:target,
      &:hover,
      &:focus,
      &:active,
      &.active {
        a {
          border:                    0;
          border-bottom:             2px solid var(--Support-Success, #81BAA7);
        }
      }
    }
  }

  .sub-title {
    font-size:                       14px;
  }

  .col-md-8 {
    min-height:                      calc(100dvh - 228px) !important;
    max-height:                      calc(100dvh - 228px) !important;
    padding:                         0 24px;
  }
}

@media (max-width: 1500px) {
  .col-md-8 {
    min-height:                      calc(100vh - 303px);
    max-height:                      calc(100vh - 318px);
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import UserChangePasswordOffcanvas from './UserChangePasswordOffcanvas.vue'
import UserChangePinOffcanvas from './UserChangePinOffcanvas.vue'
import UserPermissionsOffcanvas from './UserPermissionsOffcanvas.vue'

export default {
  name: 'UserDataTab',
  props: ['user_id', 'alertmsg'],
  components: {
    UserChangePinOffcanvas,
    UserChangePasswordOffcanvas,
    UserPermissionsOffcanvas,
  },
  data: () => ({
    loading: false,
    alert: {
      message: null,
      class: null
    },
    session: {
      collaborator: {},
      currentClinic: {}
    },
    showChangePin: false,
    showModalPermissions: false,
    showModalCropper: false,
    showChangePassword: false,
    disabled: true,
    userData: {
      activeUser: null,
      accessCode: "",
      collaborator: {
        acceptedTerm: {
          accepted: true,
          date: "",
          ip: "",
        },
        countryCode: "",
        createdAt: "",
        currentClinic: "",
        deleted: false,
        document: "",
        email: "",
        firstName: "",
        id: "",
        lastName: "",
        medic: false,
        npi: "",
        photo: "",
        rawName: "",
        signature: "",
        status: "",
        telephone1: "",
        telephone2: "",
        telephone3: "",
        updatedAt: "",
        uuid: "",
      },
      createdAt: "",
      email: null,
      enforceMFA: false,
      id: "",
      mfa: null,
      mfaEnabled: false,
      passwordSecurity: null,
      passwordWasChanged: false,
      patient: null,
      permissions: "",
      updatedAt: "",
      username: "",
      uuid: "",
    },
    photo: null,
    signature: null,
    cropperType: null,
    imageSource: null,
    mfa: {
      email: {},
      mobile: {}
    },
    hasFormChanged: false,
    initialForm: {},
    changedFormItems: [],
  }),
  watch: {
    alertmsg: function(newVal) {
      if (newVal) {
        this.alert.message = newVal.message
        this.alert.class = newVal.class
      } else {
        this.alert.message = null
      }
    },
    user_id: {
      immediate: true,
      handler: function (user_id) {
        if (user_id) {
          this.fetchData()

          this.$nextTick(() => {
            this.hasFormChanged = false
            this.changedFormItems = []
          })
        }
    }, deep: true },
    userData: {
      immediate: true,
      handler: function(newValue) {
        this.checkFormChange(newValue, 'userData')
    }, deep: true},
  },
  computed: {
    isCurrentUser() {
      return this.session.collaborator.uuid === this.userData.uuid
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.$nextTick(() => {
      this.mfaStatus(this.userData)
    })
  },
  methods: {
    checkFormChange(value, obj) {
      let hasObj = this.changedFormItems.includes(obj)
      if (JSON.stringify(value) === JSON.stringify(this.initialForm[obj])) {
        if (hasObj) {
          let index = this.changedFormItems.indexOf(obj)
          this.changedFormItems.splice(index, 1)
        }

        if (this.changedFormItems.length > 0)
          return

        this.hasFormChanged = false
      } else {
        if (!hasObj)
          this.changedFormItems.push(obj)

        this.hasFormChanged = true
      }
    },
    async fetchData() {
      let params = {
        id: this.user_id,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.getUser(params);

        this.userData = response.result
        this.photo = this.userData.collaborator.photo ? response.result.collaborator.photo : '/images/phelcom/profile_img.png'
        this.signature = this.userData.collaborator.signature

        const formWrap = {
          userData: this.userData
        }

        this.initialForm = _.cloneDeep(formWrap)
        console.log('initial form', this.initialForm);
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    mfaStatus(user) {
      let mfaUserStatus = this.$userService.mfaStatus(user)
      if (mfaUserStatus == 'label-danger') {
        // return
      } else {
        document.getElementById('mfa').click()
      }
      // return this.$userService.mfaStatus(user)
    },
    editFields() {
      this.disabled = !this.disabled;
      this.$nextTick(() => {
        this.$refs.firstName.focus()
      })
    },
    doUpdate: async function() {
      this.alert.message = null
      let params = {
        user: this.userData
      }

      params.user.npi = this.userData.collaborator.npi
      params.user.document = this.userData.collaborator.document

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.updateUser(params)
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
        this.disabled = !this.disabled
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openSignatureModal() {
      let signatureURL = await this.$userService.getSignature()
      let params = {
        type: "user.changeSignature",
        image: signatureURL.result
      }
      this.openCropperModal(params)
    },
    async openChangePhotoModal() {
      let photoURL = await this.$userService.getPhoto()
      let params = {
        type: "user.changePhoto",
        image: photoURL.result
      }

      this.openCropperModal(params)
    },
    async openChangePin() {
      const ok = await this.$refs.userChangePinOffcanvas.show({
        title: this.$i18n.t('user.changeDevicePIN'),
        // text: text,
        okButton: this.$i18n.t('user.modify'),
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log('the pin has been changed');
        // this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    async openChangePassword() {
      const ok = await this.$refs.userChangePasswordOffcanvas.show({
        title: this.$i18n.t('user.changePassword'),
        // text: text,
        okButton: this.$i18n.t('user.modify'),
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log('the password has been changed');
        // this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    async openCropperModal(params) {
      this.alert.message = null;

      const ok = await this.$refs.offcanvasCropper.show({
        title: this.$i18n.t(params.type),
        imgSrc: params.image,
        aspectRatio: params.type == 'user.changePhoto' ? 0 : null,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-primary',
        removeButton: params.type == 'user.changeSignature' ? true : false,
        cancelButton: this.$i18n.t('cancel'),
        cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        let canvas = ok.canvas

        canvas.toBlob(blob => {
          var formData = new FormData()
          formData.append('data', blob)
          this.doSaveCropper(formData, params.type, ok.removeSignature)
        }, 'image/png')
      }
    },
    async openModalPermissions() {
      const ok = await this.$refs.userPermissionsOffcanvas.show({
        title: this.$i18n.t('user.changePermission'),
        // text: text,
        okButton: this.$i18n.t('save'),
        okButtonClass: 'btn-success',
        // cancelButton: this.$i18n.t('termsPatient.doNotAccept'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        console.log('the permission has been change');
        // this.$router.push({name: 'patient-create', params: {patientID: ''}})
      }
    },
    closeModal(values) {
      values.type === 'password' ? this.showChangePassword = false : this.showChangePin = false
      this.setAlert(values.alert)
    },
    closeModalPermissions() {
      this.$nextTick(() => {
        this.showModalPermissions = false

      })
    },
    async doSaveCropper(value, type, removeSignature) {
      let params = {
        id: this.user_id,
        data: value.get('data'),
        removeSignature: removeSignature
      }

      this.loading = true
      NProgress.start()
      try {
        if (type == 'user.changePhoto') {
          this.changePhoto(params)
        } else {
          this.changeSignature(params)
        }
        this.$message.popup(this, 'general.updatedSuccess', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, 'general.updatedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.updatedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async changePhoto(params) {
      let response = await this.$userService.uploadPhoto(params)
      this.imageSource = response.result
      this.emitter.emit("profilePhotoChanged", this.imageSource);
    },
    async changeSignature(params) {
      let response = await this.$userService.uploadSignature(params)
    },
    setAlert(value) {
      if (value) {
        this.alert = value
      }
    },
    mfaSetup() {
      this.$router.push(`/user/mfa/${this.user_id}`)
    },
    doDelete: async function(deleteId) {
      this.alert.message = null

      let params = {
        id: deleteId
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$userService.remove(params);
        this.$message.popup(this, 'general.removedSuccess', 'operationSuccess', 'success')
        if (response.forceLogout) {
          window.location = '/logout'
          return
        }

        //this.fetchData()
        this.$router.push({name: 'settings'})
        
      } catch (err) {
        this.$message.popup(this, 'general.removedError', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'general.removedError', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    async openModalDelete(deleteId) {
      const ok = await this.$refs.offcanvasGeneric.show({
        modalType: 'danger',
        title: this.$i18n.t('confirm'),
        text: this.$i18n.t('user.removalConfirmation'),
        okButton: this.$i18n.t('remove'),
        okButtonClass: 'btn-danger',
        // cancelButton: this.$i18n.t('cancel'),
        // cancelButtonClass: 'btn-outline-secondary'
      })
      if (ok) {
        this.doDelete(deleteId)
      }
    },
  }
}
</script>
