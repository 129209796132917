<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <nav role="navigation">
        <a href="/" class="navbar-brand" data-test="anchorMenuMobileLogo" v-show="!isExpanded">
          <img src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo" width="112" v-if="!isPaid">
          <div class="clinic-box" v-else>
            <h1 v-if="session.currentClinic">
              {{ session.currentClinic.name }}
            </h1>

            <div class="">
              <p>
                powered by
              </p>
              <img class="logo dark-logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo" width="52">
              <img class="logo light-logo" src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo" width="52">
            </div>
          </div>
        </a>

        <div class="search-box" :class="{'open': patientList.length || isExamFilter, 'expand': isExpanded}">
          <div class="form-group">
            <IconSearchFilter @click="isExpanded = !isExpanded"/>
            <input class="form-control" type="text" name="search" v-model="searchValue" v-if="!isExamFilter"
                   :placeholder="isExamFilter ? 'Pesquisar exame de paciente...' : 'Pesquisar...'" @keyup="doSearch">
            <p class="no-margin" v-else>Filtrar exames</p>
            <div class="exam-buttons" v-if="isExamRoute && isExpanded && !patientList.length">
              <IconFilter v-if="!isExamFilter" @click="isExamFilter = !isExamFilter"/>
              <IconClose v-else @click="isExamFilter = !isExamFilter; isExpanded = !isExpanded"/>
            </div>
            <IconClose v-if="isExpanded && !isExamFilter" @click="isExpanded = !isExpanded; patientList = {}"/>
          </div>

          <div class="list-group-box" v-if="patientList.length">
            <ul class="list-group scrollbar">
              <li class="list-group-item" v-for="(item, index) in patientList" @click="toPatientDetails(item)">{{ item.fullName }}</li>
            </ul>
          </div>

          <div class="exam-filter-box" v-show="isExamFilter" id="examFilterBox">
            <ExamFilter v-if="isExamRoute" @close="isExamFilter = !isExamFilter"/>
            <!-- <ExamFilter/> -->
          </div>
        </div>

        <div class="menu-toggle" id="menuToggle" v-show="!isExpanded">
          <img class="dark-logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo" width="112" v-if="isBackdropActive">
          <img class="light-logo" src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo" width="112" v-if="isBackdropActive">

          <input type="checkbox" data-test="checkboxMenuToggle" @click="isBackdropActive = !isBackdropActive"/>
          <span class="menu-toggle-bar"></span>
          <span class="menu-toggle-bar"></span>
          <span class="menu-toggle-bar"></span>

          <ul class="menu-toggle-list" id="menu">
            <div class="">
              <MenuProfile></MenuProfile>

              <li class="nav-item" :class="active === 'exam' ? 'active' : ''">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileExam" @click="open = false; examMenu();">
                  <IconExams/>
                  <span>{{ $t('menu.exams') }}</span>
                </a>
              </li>
              <li class="nav-item" :class="active === 'patient' ? 'active' : ''" v-show="allowSynchronization">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobilePatients" @click="open = false; patientMenu()">
                  <IconPatients/>
                  <span>{{ $t('menu.patients') }}</span>
                </a>
              </li>
              <BaseFeature feature="backoffice">
                <li v-show="!isSpecialist" :class="active === 'backoffice' ? 'active' : ''">
                  <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileBackoffice" @click="open = false; backofficeMenu()">
                    <IconEye/>
                    <span>Backoffice</span>
                  </a>
                </li>
              </BaseFeature>
            </div>

            <div class="">
              <li class="nav-item" v-show="allowSynchronization">
                <a class="nav-link" :href="helpcenterUrl" target="_blank" data-test="anchorMenuMobilePatients">
                  <IconHelpMenu/>
                  <span>{{ $t('menu.help') }}</span>
                </a>
              </li>
              <li class="nav-item">
                <div class="form-floating">
                  <div class="form-check form-switch">
                    <IconMoon/>
                    <label class="form-check-label" for="darkMode">{{ $t('menu.darkMode') }}</label>
                    <input class="form-check-input" type="checkbox" role="switch" id="darkMode" data-test="checkboxDarkMode" :checked="userTheme == 'dark-theme'" @change="toggleTheme">
                  </div>
                </div>
              </li>
              <li :class="active === 'config' ? 'active' : ''">
                <a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" data-test="anchorMenuMobileSettings" @click="open = false; settingsMenu()">
                  <IconSettings/>
                  <span>{{ $t('createClinic.configuration') }}</span>
                </a>
              </li>
              <div class="powered-box">
                <p class="d-inline-block">POWERED BY</p>
                <a href="https://phelcom.com/" class="logo d-inline-block" target="_blank">
                  <img class="dark-logo" src="@/assets/images/phelcom/phelcom_logo_horizontal_blue.svg" alt="">
                  <img class="light-logo" src="@/assets/images/phelcom/phelcom_logo_horizontal_white.svg" alt="">
                </a>
                <p class="d-inline-block">v {{ appVersion }}</p>
              </div>
            </div>
          </ul>
        </div>
      </nav>
    </div>

    <div v-if="isBackdropActive" class="offcanvas-backdrop fade show"></div>
  </nav>
</template>

<style lang="scss" scoped>
.form-check.form-switch {
  @include                                  border_radius(0);
  gap:                                      0;
  padding:                                  10px;
  background:                               unset;
  border:                                   0;

  label {
    padding:                                0 !important;
  }

  input {
    width:                                  40px;
    height:                                 24px;
    opacity:                                1 !important;
    right:                                  10px;
  }
}

.search-box {
  @include                                  border_radius(28px);
  position:                                 relative;
  width:                                    calc(100% - 81px);
  max-width:                                424px;
  top:                                      0;

  .form-group {
    display:                                flex;
    flex-direction:                         row;
    align-items:                            center;
    justify-content:                        flex-end;
    gap:                                    10px;
    width:                                  100%;
    height:                                 44px;
    margin:                                 0;
    padding:                                0 20px 0 13px;

    > svg:last-child {
      width:                                20px;
      height:                               20px;
    }
  }

  .form-control {
    display:                                none;
    color:                                  var(--Shades-50);
    font-size:                              var(--font-size-14);
    font-weight:                            400;
    line-height:                            normal;
    letter-spacing:                         0.7px;
    text-overflow:                          ellipsis;
    overflow:                               hidden;
    width:                                  calc(100% - 35px) !important;
    height:                                 44px;
    padding:                                0;
    border:                                 0;

    &:focus {
      @include                              box_shadow(unset);
      outline:                              unset;
    }
  }

  .exam-filter-box {
    position:                               absolute;
    width:                                  100vw;
    height:                                 100%;
    max-height:                             0px;
    background:                             white;
    visibility:                             hidden;
  }

  p {
    color:                                  var(--Shades-50);
    font-size:                              var(--font-size-14);
    font-weight:                            400;
    line-height:                            normal;
    letter-spacing:                         0.7px;
    text-transform:                         capitalize;
    text-align:                             left;
    width:                                  100%;
    margin:                                 0;
  }

  .list-group-box {
    position:                               absolute;
    width:                                  100vw;
    height:                                 100%;
    max-height:                             0px;
    padding:                                40px 10px 20px 20px;
    background:                             white;
    visibility:                             hidden;
  }

  ul {
    list-style-type:                        none;
    height:                                 100%;
    padding-right:                          10px;
    overflow-y:                             auto;

    li {
      @include                              border_radius(0);
      color:                                var(--Shades-70);
      font-size:                            var(--font-size-14);
      font-weight:                          400;
      line-height:                          normal;
      letter-spacing:                       0.7px;
      margin-bottom:                        17px;
      border-bottom:                        1px solid var(--Shades-30-Border);
      border-top:                           0;
      border-right:                         0;
      border-left:                          0;
      cursor:                               pointer;
    }
  }

  &.open {
    // @include                                border_radius(28px 28px 0 0);
    // @include                                box_shadow(0px 4px 4px 0px rgba(0, 0, 0, 0.25));
    border:                                 0;

    .list-group-box {
      width:                                100vw;
      min-height:                           calc(100vh - 44px);
      top:                                  42px;
      left:                                 -16px;
      visibility:                           visible;

      ul {
        max-height:                         220px;
      }

      &,
      ul {
        height:                             auto;
      }
    }

    .exam-filter-box {
      // width:                                100vw;
      min-height:                           calc(100vh - 44px);
      top:                                  42px;
      left:                                 -16px;
      visibility:                           visible;
    }
  }

  &.expand {
    width:                                  100%;

    .form-group {
      justify-content:                      space-between;
      padding:                              0;
    }

    .form-control {
      display:                              flex;
    }
  }
}

.clinic-box {
  display:                                  flex;
  flex-wrap:                                wrap;
  flex-direction:                           column;
  justify-content:                          flex-start;
  align-items:                              flex-start;
  width:                                    auto;

  > div {
    display:                                flex;
    align-items:                            center;
    gap:                                    6px;
  }

  h1 {
    font-size:                              var(--font-size-18);
    font-weight:                            500;
    line-height:                            normal;
    letter-spacing:                         1.1px;
    white-space:                            nowrap;
    text-overflow:                          ellipsis;
    overflow:                               hidden;
    background:                             linear-gradient(90deg, #1B294A 0.02%, #3CB391 99.98%);
    background-clip:                        text;
    -webkit-background-clip:                text;
    -webkit-text-fill-color:                transparent;
    // width:                                  100%;
    max-width:                              300px;
    margin-bottom:                          5px;
  }

  p {
    color:                                  var(--Shades-40);
    font-size:                              8px;
    font-weight:                            500;
    line-height:                            normal;
    letter-spacing:                         1.12px;
    text-transform:                         uppercase;
    text-overflow:                          ellipsis;
    overflow:                               hidden;
    margin:                                 0;
  }
}

.dark-theme {
  .clinic-box {
    h1 {
      background:                           linear-gradient(90deg, #A8FFEF 0.02%, #3CB391 99.98%);
      background-clip:                      text;
      -webkit-background-clip:              text;
      -webkit-text-fill-color:              transparent;
    }
  }
}

.container-fluid {
  & > nav {
    display:                                flex;
    justify-content:                        space-between;
    width:                                  100%;

    .profile {
      margin-top:                           0;
      margin-bottom:                        20px;
    }

    .powered-box {
      text-align:                           center;
      justify-content:                      center;
      margin-top:                           20px;
      p {
        color:                              var(--Brand-Primary);
        font-size:                          var(--font-size-8);
        font-weight:                        400;
        letter-spacing:                     1.72px;
        margin:                             0;
      }
      .logo {
        color:                              #1B294A;
        width:                              101px;
        text-decoration:                    none;
        img {
          width:                            101px !important;
        }
      }
    }
  }
}

.menu-toggle {
  display:                                  flex;
  flex-direction:                           column;
  height:                                   35px;
  width:                                    35px;
  margin:                                   0;
  padding:                                  18px 7px 5px;
  // background-color:                         #727272;
  -webkit-user-select:                      none;
  user-select:                              none;

  > img {
    position:                               absolute;
    top:                                    18px;
    left:                                   19vw;
    z-index:                                1055;
  }

  .menu-toggle-bar {
    @include                                transition_three(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease);
    position:                               relative;
    display:                                flex;
    height:                                 2px;
    width:                                  20px;
    margin-bottom:                          2px;
    background:                             var(--Brand-Secondary);
    border-radius:                          3px;
    z-index:                                1;
    transform-origin:                       5px 0px;
    z-index:                                1044;

    &:first-child {
      transform-origin:                     0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin:                     0% 100%;
    }
  }

  input {
    position:                               absolute;
    display:                                flex;
    height:                                 32px;
    width:                                  40px;
    cursor:                                 pointer;
    opacity:                                0;
    z-index:                                1045;

    &:checked ~ .menu-toggle-bar {
      @include                              transform(rotate(45deg) translate(-3px, -1px));
      opacity:                              1;
      background:                           var(--Brand-Secondary);
      top:                                  4px;
      left:                                 4px;

      &:nth-last-child(3) {
        @include                            transform(rotate(0deg) scale(0.2, 0.2));
        opacity:                            0;
      }

      &:nth-last-child(2) {
        @include                            transform(rotate(-45deg) translate(0, -1px));
      }

      & ~ ul {
        transform:                          none !important;
      }
    }
  }

  .menu-toggle-list {
    @include                                border_radius(10px 0 0 10px);
    @include                                transform(translate(100%, 0));
    @include                                transition(transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0));
    position:                               fixed;
    top:                                    0;
    right:                                  0;
    display:                                flex;
    justify-content:                        space-between;
    flex-direction:                         column;
    list-style:                             none;
    width:                                  90vw;
    height:                                 100vh;
    // margin:                                 -27px 0 0 -32px;
    padding:                                20px;
    padding-top:                            74px;
    background:                             var(--Shades-20);
    -webkit-font-smoothing:                 antialiased;
    transform-origin:                       100% 0%;
    z-index:                                1043;

    li {
      @include                              border_radius(99px);
      margin-bottom:                        10px;
      border:                               1px solid var(--Shades-30-Border);
      background:                           var(--Shades-BG);

      &:hover,
      &:focus,
      &:active,
      &.active {
        border:                             1px solid var(--Brand-Secondary);
        background:                         #CAF1E6;
        a, i:before {
          color:                            var(--Brand-Secondary) !important;
        }
      }
      a {
        font-size:                          12px;
        font-weight:                        500;
        color:                              var(--Shades-50);
        text-transform:                     uppercase;
        letter-spacing:                     2.58px;
        display:                            flex;
        align-items:                        center;
        padding:                            5px;
        text-decoration:                    none;
        cursor:                             pointer;
      }
      svg {
        width:                              25px;
        height:                             25px;
      }
    }

    div:last-child {
      li:not(:first-child) {
        // margin-bottom:                      0;
      }
    }
    img {
      width:                                35px;
      height:                               35px;
    }
    i {
      font-size:                            2em;
      vertical-align:                       middle;
      margin-right:                         10px;
    }
    img,
    svg {
      margin-right:                         10px;
    }
  }
}

.navbar {
  position:                                 fixed;
  border:                                   0;
  padding:                                  0;
  width:                                    100%;
  height:                                   55px;
  top:                                      0;
  background:                               var(--Shades-BG);
  border-bottom:                            1px solid var(--Shades-20-Border);
  z-index:                                  999;

  .container-fluid {
    padding-left:                           15px;
    padding-right:                          15px;
  }
  .navbar-brand {
    font-size:                              unset;
    line-height:                            unset;
    height:                                 unset;
    margin-right:                           0;
    // z-index:                                2;
    // width:                                  calc(100% - 150px);
    // padding:                                12px 0;
    max-height:                             50px;
    i {
      color:                                white;
      font-size:                            2.3em;
    }
  }
  .btn-icon {
    margin-bottom:                          0;
    height:                                 35px;
    width:                                  35px;
    padding:                                5px;
    background-color:                       #727272;
    border:                                 0;
    &.btn-notif {
      padding:                              4px;
      position:                             relative;
      z-index:                              0;
      .notification-count {
        @include                            border_radius(50%);
        font-size:                          12px;
        position:                           absolute;
        top:                                -4px;
        background-color:                   $arylide;
        right:                              -4px;
        width:                              16px;
        height:                             16px;
      }
    }
    i {
      font-size:                            2em;
    }
  }
}

.progress-box {
  margin-bottom:                            15px;
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamFilter from '../exam/ExamFilter.vue'
import MenuProfile from './MenuProfile.vue'
import MenuStorageBar from './MenuStorageBar.vue'
import Notifications from './Notifications.vue'
import profile_img from "@/assets/images/phelcom/profile_img.png";

export default {
  name: 'MenuMobileLinks',
  props: ['isResize'],
  components: {
    ExamFilter,
    MenuProfile,
    MenuStorageBar,
    Notifications
  },
  data: () => ({
    active: null,
    open: true,
    openNotif: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
    notificationData: {
      count: 0,
      events: []
    },
    notificationCount: 0,
    notification: [],
    appVersion: process.env.VUE_APP_VERSION,
    isPaid: true,
    isBackdropActive: false,
    isExpanded: false,
    isExamFilter: false,
    patientList: {},
    searchValue: null,
    locale: null,
    userTheme: 'light-theme',
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        console.log('watch', this.$activeLink.value);
        this.active = this.$activeLink.value
    }, deep: true }
  },
  computed: {
    avatar() {
      return this.session.userPhoto ? this.session.userPhoto : profile_img;
    },
    isExamRoute() {
      return this.$router.currentRoute._value.path == '/exam/' || this.$router.currentRoute._value.path == '/exam'
    },
    helpcenterUrl() {
      console.log('url???');
      if (this.locale == 'pt-br')
        return 'https://phelcom.com/pt-br/helpcenter/'

      return 'https://phelcom.com/helpcenter/'
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.currentClinicID = this.session.currentClinic.id

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull
    this.active = this.$activeLink.value ? this.$activeLink.value : null
    this.locale = this.session.currentClinic.locale.toLowerCase()

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true

    this.$nextTick(() => {
      this.reloadNotifications()
    })

    // called from notificationService (logic is there)
    this.emitter.on("newNotification", event => {
      this.notification.push(event)
      this.notificationCount += 1 // view logic
    })

    const initUserTheme = this.getTheme() || this.getMediaPreference()
    this.setTheme(initUserTheme)
  },
  methods: {
    profileMenu: async function() {
      this.session.activeLink = 'users'
      this.$router.push(`/user/${this.session.user.id}`)
    },
    dashboardMenu: async function(useRouter) {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    settingsMenu: async function() {
      this.goToLink(this, 'settings', 'settings')
    },
    toggleMenu(val) {
      this.open = val
    },
    async logout() {
      await this.$authService.logout(this)
      window.open('/', '_self');
    },
    goToLink(self, active, link) {
      this.toggleMenu(false);
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    },
    backofficeMenu() {
     const IS_PRODUCTION = process.env.NODE_ENV == 'production'
     let BASE_URL = 'http://bo2.eyercloud.com/'
     if (!IS_PRODUCTION){
        BASE_URL = 'http://devbo2.eyercloud.com/'
      }
      window.location = `${BASE_URL}`
    },

    reloadNotifications() {
      if (!this.currentClinicID)
        return

      const clinicData = this.$notificationService.reloadNotifications(this.currentClinicID)
      for (let e of clinicData.events) {
        this.addNotification(e)
      }

      this.notificationCount = clinicData.count
    },
    // bell icon clicked
    resetNotifications() {
      this.notificationCount = 0
      this.$notificationService.resetCount(this.currentClinicID)
      this.$notificationService.enable() // force notification activation
    },
    addNotification(data) {
      try {
        this.notification.unshift(data)
      } catch (e) {
        console.log('error(addNotification)', e);
      }
    },
    notificationClicked: async function(examid) {
      try {
        let params = {
          id: examid,
        }

        let response = await this.$examService.get(params);

        let data = this.$utils.dataQuery({
          exam: JSON.stringify(response.exam)
        })

        this.$router.push({name: 'exam-show', params: {examID: examid}, query: {tab: 'images', data: data}})
        // this.$router.push({name: 'proxy-router', params:{
        //   target: 'exam-show', data: {exam: response.exam, tab: null, examid: examid}
        // }})
      } catch(err) {
        this.alert = {
          message: `${this.$i18n.t('request_error')} <strong>${err}</strong>`,
          class: 'alert-danger'
        }
      }
    },
    async doSearch() {
      let params = {
        partialName: this.searchValue
      }

      let partialNameSize = params.partialName.length

      if (partialNameSize == 0)
        this.patientList = {}

      if (partialNameSize < 3)
        return

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.searchPatient(params)
        this.patientList = response.result
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    toPatientDetails(item) {
      this.searchValue = ''
      this.patientList = {}
      this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})
    },
    toggleExamFilter() {
      this.isExamFilter = !this.isExamFilter
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem('user-theme');
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme');
      } else {
        this.setTheme('light-theme');
      }
    },

    getTheme() {
      return localStorage.getItem('user-theme');
    },

    setTheme(theme) {
      localStorage.setItem('user-theme', theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      if (hasDarkPreference) {
        return 'dark-theme';
      } else {
        return 'light-theme';
      }
    },
  }
}

</script>
