<template>
  <div class="main-menu">
    <ul class="menu-links">
      <div class="">
        <!-- <li v-if="!isSpecialist" :class="active === 'dashboard' ? 'active' : ''">
          <a data-test="anchorMenuDesktopDashboard" @click="dashboardMenu()">
            <IconDashboard/>
            <span>{{ $t('menu.dashboard') }}</span>
          </a>
        </li> -->
        <div v-show="allowSynchronization">
          <li :class="active === 'exam' ? 'active' : ''">
            <a data-test="anchorMenuDesktopExam" @click="examMenu()">
              <IconExams/>
              <span>{{ $t('menu.exams') }}</span>
            </a>
          </li>
          <li :class="active === 'patient' ? 'active' : ''">
            <a data-test="anchorMenuDesktopPatients" @click="patientMenu()">
              <IconPatients/>
              <span>{{ $t('menu.patients') }}</span>
            </a>
          </li>
        </div>
        <BaseFeature feature="backoffice">
          <li v-show="!isSpecialist" :class="active === 'backoffice' ? 'active' : ''">
            <a data-test="anchorMenuDesktopBackoffice" @click="backofficeMenu()">
              <IconEye/>
              <span>Backoffice</span>
            </a>
          </li>
        </BaseFeature>
      </div>

      <div class="">
        <li>
          <div class="form-floating">
            <div class="form-check form-switch">
              <IconMoon @click="toggleTheme"/>
              <label class="form-check-label" for="darkMode">{{ $t('menu.darkMode') }}</label>
              <input class="form-check-input" type="checkbox" role="switch" id="darkMode" data-test="checkboxDarkMode" :checked="userTheme == 'dark-theme'" @change="toggleTheme">
            </div>
          </div>
        </li>

        <li :class="active === 'settings' ? 'active' : ''">
          <a data-test="anchorMenuDesktopSettings" @click="settingsMenu()">
            <IconSettings/>
            <span>{{ $t('menu.settings') }}</span>
          </a>

          <teleport to="body" v-if="showTips">
            <div class="tips" id="tips">
              <button class="no-btn" type="button" name="button" @click="closeAndIncrementTipsCount">
                <IconClose/>
              </button>
              <p>
                {{$t('welcomeTips.config')}}
              </p>
            </div>
          </teleport>
        </li>
      </div>

    </ul>
  </div>
</template>

<style lang="scss" scoped>
  .tips {
    bottom:                             5.5rem;
    left:                               2.5rem;
  }

  .form-check.form-switch {
    @include                            border_radius(0);
    gap:                                0;
    padding:                            10px;
    background:                         unset;
    border:                             0;

    label {
      padding:                          0 !important;
    }
  }

  .main-menu {
    height:                             calc(100% - 30px); // 171
  }
  .menu-links {
    display:                            flex !important;
    justify-content:                    space-between;
    flex-direction:                     column;
    list-style:                         none;
    padding:                            30px 0 0 0;
    height:                             100%;
    gap:                                10px;
    li {
      @include                          border_radius(99px);
      position:                         relative;
      margin-bottom:                    10px;
      border:                           1px solid var(--Shades-30-Border);
      // border:                           1px solid #DCDCDC;
      background:                       var(--Shades-W);
      &:hover,
      &:focus,
      &:active,
      &.active {
        border:                         1px solid var(--Brand-Secondary);
        background:                     #CAF1E6;
        a,
        label,
        i:before {
          color:                        var(--Brand-Secondary) !important;
        }
      }
      a,
      .form-check label {
        font-size:                      12px;
        font-weight:                    500;
        color:                          var(--Shades-50);
        text-transform:                 uppercase;
        letter-spacing:                 2.58px;
        display:                        flex;
        align-items:                    center;
        padding:                        10px;
        text-decoration:                none;
        cursor:                         pointer;
      }
    }
    > li {
      a {
        svg {
          * {
            stroke: inherit;
          }
        }
      }
    }
    img {
      width:                            35px;
      height:                           35px;
    }
    i {
      font-size:                        2em;
      vertical-align:                   middle;
      margin-right:                     10px;
    }
    img,
    svg {
      margin-right:                     10px;
    }
    svg {
      width:                            25px;
      height:                           25px;
    }
  }

  .main.retract {
    .main-menu {
      // height:                           calc(100% - 201px);
    }
    .menu-links {
      li {
        width:                          35px;
        height:                         35px;
        a {
          display:                      flex;
          padding:                      5px;
        }
        span {
          display:                      none;
        }
        svg {
          margin:                       0;
        }
        img {
          display:                      block;
          width:                        25px;
          height:                       25px;
          margin:                       0 auto;
        }
      }
    }
    .form-check.form-switch {
      padding:                          6px;

      label, input {
        display:                        none;
      }
    }
  }

  @media (max-width: 767px) {
    .menu-links {
      a span {
        display:                        none;
      }
      li a {
        padding:                        10px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1500px) {
    .menu-links {
      li a {
        // padding:                        7px 15px;
      }
      img, svg {
        max-height:                     22px;
      }
      .form-check svg {
        max-height:                     20px;
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 991px) {
    @media screen and (min-height: 600px) and (max-height: 768px) {
      .menu-links {
        li a {
          font-size:                    10px;
          // padding:                      10px 15px;
        }
        img, svg {
          max-height:                   18px;
        }
      }
    }
  }

  @media (min-width: 1500px) {
    .main.retract {
      .form-check.form-switch {
        padding:                         3px 6px;
      }
    }
  }
</style>

<script>
import { useUserStore } from '@/store/user'

export default {
  name: 'MenuDesktopLinks',
  data: () => ({
    active: null,
    showModalWarning: false,
    allowSynchronization: true,
    isSpecialist: null,
    isPowerfull: null,
    session: null,
    showTips: true,
    userTheme: 'light-theme',
  }),
  watch: {
    '$activeLink.value': {
      handler: function () {
        this.active = this.$activeLink.value
    }, deep: true }
  },
  mounted: function() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo

    this.isSpecialist = this.session.isSpecialist
    this.isPowerfull = this.session.isPowerfull

    if (this.session.collaborator.welcomeTips)
      this.showTips = false

    this.active = this.$activeLink.value ? this.$activeLink.value : null

    this.allowSynchronization = this.session.currentClinic.synchronizePatientData
    if (this.allowSynchronization == undefined)
      this.allowSynchronization = true

    const initUserTheme = this.getTheme() || this.getMediaPreference()
    this.setTheme(initUserTheme)
  },
  methods: {
    dashboardMenu: async function() {
      this.goToLink(this, 'dashboard', 'dashboard')
    },
    examMenu: async function() {
      this.session.examCurrentPage = 1
      this.goToLink(this, 'exam', 'exam-list')
    },
    patientMenu: async function() {
      this.session.patientCurrentPage = 1
      this.goToLink(this, 'patient', 'patient-list')
    },
    settingsMenu: async function() {
      this.goToLink(this, 'settings', 'settings')
    },
    backofficeMenu() {
     const IS_PRODUCTION = process.env.NODE_ENV == 'production'
     let BASE_URL = 'http://bo2.eyercloud.com/'
     if (!IS_PRODUCTION){
        BASE_URL = 'http://devbo2.eyercloud.com/'
      }
      window.location = `${BASE_URL}`
    },
    goToLink(self, active, link) {
      self.active = active

      self.$router.push({name: 'proxy-router', params: {
        target: link,
        data: JSON.stringify({})
      }})
    },
    closeAndIncrementTipsCount() {
      this.showTips = false
      const userStore = useUserStore()
      userStore.incrementTipsCount(1)
    },

    toggleTheme() {
      const activeTheme = localStorage.getItem('user-theme');
      if (activeTheme === 'light-theme') {
        this.setTheme('dark-theme');
      } else {
        this.setTheme('light-theme');
      }
    },

    getTheme() {
      return localStorage.getItem('user-theme');
    },

    setTheme(theme) {
      localStorage.setItem('user-theme', theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      if (hasDarkPreference) {
        return 'dark-theme';
      } else {
        return 'light-theme';
      }
    },
  }
}
</script>
