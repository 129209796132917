<template>
  <div class="main-report">
    <div class="row" v-if="showDisplay">
      <div class="col-lg-12">
        <div class="report-actions">
          <IconCheckBig/>
          <h1>{{ $t('report.reportCreated') }}</h1>
          <p>
            {{ $t('report.reportCreatedBy') }}
            <strong v-if="report.responsible">{{ `${report.responsible.firstName} ${report.responsible.lastName}` }}</strong>
            {{ $t('report.reportCreatedOn') }} {{ $filters.date(report.date) }} {{ $t('report.reportCreatedAt') }} {{ $filters.time(report.createdAt) }}
          </p>

          <!-- <button class="btn btn-success" type="button" name="button">imprimir laudo</button> -->
          <button class="btn btn-success" type="button" name="button" @click="downloadPDF()">download</button>

          <p>{{ $t('report.reportChanged') }}</p>

          <button class="btn btn-outline-primary" type="button" name="button" @click="showDisplay = !showDisplay">{{ $t('report.reportEdit') }}</button>
        </div>
      </div>
      <!-- <div class="col-lg-6">

      </div> -->
    </div>

    <Report v-else :report_id="report.id" :patient="patient" :exam_id="exam_id" @cancel="showDisplay = !showDisplay"/>
  </div>
</template>

<style lang="scss" scoped>
.main-report,
.row {
  height:                        100%;
}

.col-lg-12 {
  &:first-child {
    display:                     flex;
    justify-content:             center; // flex-end with col-lg-6 and pdf preview
    align-items:                 center;
  }
}
.report-actions {
  display:                       flex;
  flex-direction:                column;
  align-items:                   center;
  text-align:                    center;
  gap:                           18px;
  max-width:                     385px;
  padding-bottom:                10px;

  h1,
  p {
    color:                       var(--Brand-Primary);
    margin:                      0;
  }

  h1 {
    font-size:                   38px;
    font-weight:                 300;
    line-height:                 normal;
  }

  p {
    font-size:                   var(--font-size-14);
    font-weight:                 400;
    line-height:                 140%; /* 19.6px */
    margin:                      0;
    max-width:                   80%;
  }

  .btn {
    font-size:                   var(--font-size-14);
    font-weight:                 700;
    line-height:                 normal;
    letter-spacing:              4.2px;
    text-transform:              uppercase;
    width:                       100%;
    padding:                     20px;
  }

  .btn-outline-primary {
    color:                       var(--Brand-Primary);
    border-color:                var(--Brand-Primary);

    &:hover,
    &:focus,
    &:active,
    &.active {
      color:                     white;
      background:                var(--Brand-Primary);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  .report-actions {
    gap:                         10px;
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'
import ExamReportList from './ExamReportList.vue'
import Report from '../../report/report.vue'

export default {
  name: 'ExamReportTab',
  props: ['exam_id', 'patient'],
  components: {
    ExamReportList,
    Report
  },
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    session: null,
    report: {
      id: null
    },
    showDisplay: true,
  }),
  watch: {
    report: {
      immediate: true,
      handler: function(newValue) {
        //new report, dismiss display
        if (newValue){
            this.report = newValue           
            this.showDisplay = true
        }
    }, deep: true},
    
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    
    this.fetchReportData()

  },
  methods: {
    async fetchReportData() {
      // this.alert.message = null;
      let params = {
        id: this.exam_id,
      }

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$reportService.getList(params);
        if (response.result.length > 0) {
          this.report = response.result[response.result.length - 1]
          this.showDisplay = true
          // HOTFIX: If report is a "empty" one, automaticly made by Eyer2, allow editing from start
          if (this.report.diagnosis == "" && this.report.diagnosisReferences.length == 0) {
            this.showDisplay = false
          }
        } else {
            this.showDisplay = false
        }
      } catch(err) {
        // this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    downloadPDF() {
      let params = {
        id: this.report.id
      }
      this.$reportService.generateReport(params);
    },
    doEdit() {
      this.showDisplay = false
      // let data = {}
      // if (this.$route.query.data) {
      //   data = this.$route.query.data
      // }
      //
      // console.log('report', report);
      // report.type != 'general' ?
      //   this.$router.push({name: 'report', params: {id: report.id, examID: this.exam.id}, query: {data: data}})
      //     : this.$router.push({name: 'report-general', params: {id: report.id, examID: this.exam.id, patientID: this.patient.id}, query: {data: data}})
    },
  },
  // unmounted() {
  //   this.alert.message = null
  // }
}
</script>
